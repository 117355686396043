import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Borrador, DocumentoAsociadoPojo, DocumentoTipoEnum, DocumentoTipoEnum as DocumentoTipo, FiltroWo, FormaPago, FormaPagoClasificacionEnum, InformacionCruces, InformacionMovimientos, ListenerPojo, MovimientoTipoEnum, Operador, PaginacionWo, SeveridadEnum, TipoDato, TipoFiltro, TipoTercero, ValidacionBotoneraDocumentos, ValidacionBotonMas, ValidacionElaboradoPor, ValidacionPestanaClasificacion, ValidacionPestanaContacto, ValidacionPestanaOtros } from '@wo/modelo';
import { ProductosDBService } from '@wo/pos/service/productos-db.service';
import { OnlineOfflineService } from '@wo/pos/service/online-offline.service';
import { Tercero } from '@wo/pos/modelo/tercero';
import { TerceroTipoIdentificacion } from '@wo/pos/modelo/terceroTipoIdentificacion';
import { Moneda } from '@wo/pos/modelo/moneda';
import { FormasDePago } from '@wo/pos/modelo/formasDePago';
import { Prefijo } from '@wo/pos/modelo/prefijo';
import { Subject, Subscription } from 'rxjs';
import { ColaService, DocumentoEncabezadoService, FormatoUtilService, ListenerBorradorService, PosService, TabsService } from '@wo/servicios';
import { debounceTime, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { PopUpService } from '@wo/frontend/servicios/popUp/pop-up.service';
import { DatePipe } from '@angular/common';
import { LoaderService } from '@wo/frontend/servicios/loader/loader.service';

@Component({
  selector: 'app-encabezado',
  templateUrl: './encabezado.component.html',
  styleUrls: ['./encabezado.component.css']
})
export class EncabezadoComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {

  @Input() parametros: any = {};
  @Input() datosEncabezado: any = {};
  @Input() nuevoCliente: any = {};
  @Input() nuevaDireccion: any = {};
  @Input() completo: boolean;
  @Input() borrador: boolean = true;
  @Input() disableBox: boolean = false;
  @Input() online: boolean;
  @Input() sincronizando: boolean;
  @Input() crearTerceroOffline: boolean;
  @Input() permisosPOS: any = {};
  @Input() camposPersonalizadosIncompletos: boolean = true;

  @Output() cambioDatosEncabezado = new EventEmitter<boolean>();
  @Output() verMasOpciones = new EventEmitter<any>();
  @Output() crearTercero = new EventEmitter<any>();
  @Output() crearDireccion = new EventEmitter<any>();

  documentoEncabezadoCopia: any = {};
  tieneCupo: boolean = false;
  sinCredito: any;
  idGlobal: number;
  formularioValido: Boolean = false;
  tablaActiva: boolean = true;
  nombreEmpresa: any;
  dataMoneda: any;
  dataCliente: any = [];
  dataTodasFormaDePago: any = [];
  dataFormaDePago: any;
  dataPrefijo: any = [];
  facturaVentaId: string
  filtroClientes: any;
  filtroClientes2: any;
  filtroClientes3: any;
  filtroFormasDePago: any;
  filtroFormasDePago2: any;
  filtroFormasDePago3: any;
  filtroPrefijo: any;
  filtroPrefijo2: any;
  filtroPrefijo3: any;
  filtroInventario: any;
  filtroVendedor: any;
  filtroVendedor2: any;
  filtroVendedor3: any;
  empresaEncabezado: any;
  prefijoEncabezado: any;
  dataClienteId: any;
  listaClient: any[] = [];
  listaCliente2: any[] = [];
  listaVendedor: any[] = [];
  dataVendedor: any[] = [];
  listaVendedor2: any[] = [];
  listaMoneda: Moneda[] = [];
  listaDirecciones: any[] = [];
  listaDirecciones2: any[] = [];
  direccionElegida: any = null;
  dataEmpresa: any = [];
  filtros: any = {
    empresa: [],
    cliente: [],
    vendedor: [],
  };
  actualizarEmpresa;
  public subscriptions = new Subscription();
  direccionCambio: any;
  direccionCambio2: any;
  tipoDocumento = DocumentoTipoEnum.POS;
  direccionesCliente;
  totalPaginasDireccion;
  conceptoSize;
  estiloDisabled;
  actualizarTerExterno;
  actualizarTerInterno;
  actualizarFormaPago;
  clientePrueba: any;
  mostrarBotonMas: boolean = true;
  mostrarImprimirFactura: boolean;
  dataInicializarImprimir: any;
  public onlineOffline: boolean = navigator.onLine;
  monedaAux = null;
  comenzoComoBorrador: boolean = false;
  documentoBorradorActual: string;
  borradorMovimiento: boolean = false;
  primerClick = true;
  listaDocumentosAsociados = [];
  idDocumentoAsociado: number;
  documentoAsociado: DocumentoAsociadoPojo;
  documentoAsociadoCopia: DocumentoAsociadoPojo;
  moneda: Moneda;
  monedas: Array<Moneda> = [];
  valorTRM: string;
  valorTRMnumeric: string;
  ultimoNumeroValido: number;
  imagenPopup: string = '';
  valorAnteriorSelect: any;
  valorAnteriorMoneda: any;
  valorAnteriorDirecciones: any;
  clienteAnterior;
  tercerosClientes: Array<Tercero> = [];
  tercerosVendedores: Array<Tercero> = [];
  cliente: Tercero;
  idCliente = undefined;
  idVendedor = undefined;
  vendedor: Tercero;
  navegarCliente: Boolean;
  navegarTercero: boolean;
  navegarResoluciones: boolean;
  prefijoSeleccionado: any = null;
  documentoEncabezado: any = {};
  idDocumento: any;
  documentoExternoOpcion = {
    "documentoTipo.nombreDocumento": "Documento Electrónico Externo",
    fecha: "",
    id: -10,
    numero: null,
    "prefijo.nombre": "",
    trm: "1"
  };
  formasPago: Array<FormaPago> = [];
  formaPago: FormaPago;
  valorActualDireccion: any;
  estadoAjustadoInventario: boolean;
  encabezadoCompleto: any;
  msjeCrearTRM: boolean;
  idClientePopUpDireccion: String;
  estaActive_1: string = 'tabActive';
  estaActive_2: string = '';
  screen = 'PuntoDeVenta';
  fechaAnterior: any;
  fechaFinalAnterior: any;
  fechaPagoAnterior: any;
  docPresentadoElectronicamente: boolean;
  estadoPresentadoElectronicamente: boolean;
  documentoVentas: boolean;
  permisos;
  ultimoKey: any = undefined;
  numeroDisabled: boolean = true;
  prefijoExternoValido: string;
  numeroExternoValido: string;
  clienteValido: Tercero;
  contabilizo: boolean;
  contabilizando: boolean;
  informacionRenglones: InformacionMovimientos;
  informacionCruces: InformacionCruces;
  estadoAnulado = false;
  estadoBloqueado = false;
  pestanasBloqueadas: any;
  motivoDevolucion;
  clasificacionNotaCredito;
  estaDocumentoExterno: boolean = false;
  mostrarSelect: boolean = true;
  empresas = [];
  crucesNoEditables: boolean;
  mostrarDocumentoAsociado: boolean = false;
  filtrosTerInterno: Array<FiltroWo> = [];
  filtrosDocumentoAsociado: Array<FiltroWo> = [];
  filtrosTerExterno: Array<FiltroWo> = [];
  filtrosTerDireccion: Array<FiltroWo> = [];
  filtrosMotivoDevolucion: Array<FiltroWo> = [];
  filtrosClasificacion: Array<FiltroWo> = [];
  filtrosEmpresa: Array<FiltroWo> = [];
  filtrosFormaPago: Array<FiltroWo> = [];
  filtrosPrefijo: Array<FiltroWo> = [];
  filtroMonedaDisponible: Array<FiltroWo> = [];
  docVerificado = false;
  docExportado = false;
  docbloqueado = false;
  updateRenglones = true;
  docAjustadoInv = false;
  monedaLocal = JSON.parse(sessionStorage.getItem('MONEDALOCAL'));
  usuario = JSON.parse(sessionStorage.getItem('USUARIOSESION'));
  paisLocal = JSON.parse(sessionStorage.getItem('PAISLOCAL'));
  botoneraRenglones: ValidacionBotoneraDocumentos;
  tipoMovimiento: string;
  cerrar: boolean = false;
  urlDetalleDocumento: string = '/panel/punto-de-venta';
  cambios: ListenerPojo[];
  urlActual: string;
  accionEliminarDocumentoConDocumentoAsociado: string
  accionAnularDocumentoConDocumentoAsociado: string;
  idDocEnc: string[];
  esValido: string;
  errorPopUp: any;
  temporalEncabezado: any;
  filtroClientes4: FiltroWo;
  mensajeFecha: boolean;
  fechaCambio: any;
  get linkDocumento() {
    if (this.urlDetalleDocumento) {
      return this.urlDetalleDocumento;
    } else {
      return '/panel/punto-de-venta';
    }
  };
  tab1Activa: boolean = true;
  tab2Activa: boolean;
  protected formatoUtilService: FormatoUtilService;

  terminal: any;
  mostrarPopUpMasOpciones: boolean;
  mostrarPopup2: boolean = false;
  mostrarPopupConfirmarEliminacion: boolean = false;
  mostrarPopUpDescuento: boolean = false;
  validacionBotonMas: ValidacionBotonMas = new ValidacionBotonMas();
  atributosEncabezado: boolean;
  tamVertical;
  cantidadElementos = 10;
  debounceTimeBusqueda: Subject<any> = new Subject();
  totalPagTerceros: any;

  sonidoClick: any = new Audio("assets/sonidosInterfaz/click.mp3");

  constructor(
    private posService: PosService,
    private productosDBService: ProductosDBService,
    private onlineOfflineService: OnlineOfflineService,
    public popUpService: PopUpService,
    protected route: ActivatedRoute,
    private router: Router,
    public datepipe: DatePipe,
    private serviceDocumentoEncabezado: DocumentoEncabezadoService,
    private listenerBorradorService: ListenerBorradorService,
    private tabsService: TabsService,
    protected serviceColas: ColaService,
    public _LoaderService: LoaderService
  ) {
    const tabActual = this.tabsService.activeTab;
    this.screen = tabActual.tab.nombre;
    const cierraTab = this.tabsService.cierraBorradorTab$.subscribe(respuesta => {
      this.cerrar = true;
      if (this.urlDetalleDocumento === respuesta) {
        if (this.borrador) {
          this.documentoBorradorActual = respuesta;
          this.comprobarBorrador();
        }
        let borradorPantalla: boolean = true;
        if (this.borradorMovimiento === false && this.borrador === false) {
          borradorPantalla = false;
        }
        if (this.documentoEncabezado.numero) {
          this.listenerBorradorService.actualizarBorrador(this.screen, new Borrador(borradorPantalla, 'PIERDEN_CAMBIOS_REGRESAR'));
          this.listenerBorradorService.actualizarBorrador(this.tipoDocumento, new Borrador(borradorPantalla, 'PIERDEN_CAMBIOS_REGRESAR'));
        } else {
          this.listenerBorradorService.actualizarBorrador(this.screen, new Borrador(borradorPantalla, 'PIERDEN_CAMBIOS_ATRIBUTOS'));
          this.listenerBorradorService.actualizarBorrador(this.tipoDocumento, new Borrador(borradorPantalla, 'PIERDEN_CAMBIOS_ATRIBUTOS'));
        }
        this.completo = true;
      }
    });
    this.subscriptions.add(cierraTab);

    const esBorrador = this.listenerBorradorService.esBorrador.subscribe(r => {
      if (r == false) {
        this.estaDocumentoExterno = true;
        this.verificarEncabezado();
      }
    });
    this.subscriptions.add(esBorrador);

    let url: any = this.router.url.split('/');
    url.pop();
    this.urlActual = url.join('/');
    let cambioTab = this.tabsService.cambio$.subscribe(ruta => {
    });
    this.subscriptions.add(cambioTab);

    this.subscriptions.add(
      this.debounceTimeBusqueda.pipe(debounceTime(500)).subscribe(data => {
        if (data){
          if (data.filtrar == 'ObtenerFormasDePago') {
            this.ObtenerFormasDePago(data.event);
          } else {
            this[data.filtrar == 'ObtenerCliente' || data.filtrar == 'ObtenerVendedor' ? 'obtenerTercero' : data.filtrar](data.filtrar == 'ObtenerCliente' ? 'Cliente' : 'Vendedor', data.event);
          }
        }
      }));
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.datosEncabezado && changes.datosEncabezado.currentValue) {
      this.datosEncabezado = changes.datosEncabezado.currentValue;
      this.documentoEncabezado = changes.datosEncabezado.currentValue;
    }

    if (changes && changes.nuevoCliente && changes.nuevoCliente.currentValue) {
      this.datosEncabezado.terceroExterno = changes.nuevoCliente.currentValue;
      this.documentoEncabezado.terceroExterno = changes.nuevoCliente.currentValue;
      this.cambio('terceroExterno', this.datosEncabezado.terceroExterno, 'Tercero');
    }

    if (changes && changes.nuevaDireccion && changes.nuevaDireccion.currentValue) {
      this.datosEncabezado.direccionTerceroExterno = changes.nuevaDireccion.currentValue;
      this.documentoEncabezado.direccionTerceroExterno = changes.nuevaDireccion.currentValue;
      this.cambio('direccion', this.datosEncabezado.direccionTerceroExterno, 'TerceroDireccion');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit() {
    this.tamVertical = document.getElementById('background').clientHeight;
    this.cantidadElementos = this.tamVertical && this.tamVertical < 375 ? 5 : 10;
    this.terminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
    this._LoaderService.colocarMascara('panel/punto-de-venta', { tabla: false }, 'loadingIndex', 'paneTabs');

    this.subscriptions.add(
      this.onlineOfflineService.connectionChanged().subscribe(async online => {
        if (navigator.onLine) {
          this.onlineOffline = true;
        } else {
          this.onlineOffline = false;
          if (!this.documentoEncabezado.prefijo) {
            await this.esperarPrefijoOnline();
            sessionStorage.removeItem('prefijoOnline');
          }
        }
      }));

    this.comenzoComoBorrador = this.parametros.queryParams.crea;
    if (this.parametros && this.parametros.params) {
      this.documentoEncabezado = await this.productosDBService.getDocumentoencabezado(this.parametros.params);
      this.idDocEnc = this.documentoEncabezado.encabezadoId;
      this.idDocumento = this.documentoEncabezado.encabezadoId;
      this.datosEncabezado = { ...this.documentoEncabezado };
      this.metodoInit();
    }
    this.idGlobal = parseInt(sessionStorage.getItem('idGlobalTercero'));
    this.conceptoCliente();
  }

  async metodoInit() {
    if (this.onlineOffline) {
      if (this.documentoEncabezado.empresa) {
        this.datosEncabezado.empresa = this.documentoEncabezado.empresa ? this.documentoEncabezado.empresa : {};
        this.datosEncabezado.fecha = this.documentoEncabezado.fecha;
        this.datosEncabezado.direccion = this.documentoEncabezado.direccionTerceroExterno;
        this.datosEncabezado.terceroExterno = this.documentoEncabezado.terceroExterno;
        this.datosEncabezado.terceroInterno = this.documentoEncabezado.terceroInterno;
        this.datosEncabezado.formaPago = this.documentoEncabezado.formaPago;
        this.datosEncabezado.prefijo = this.documentoEncabezado.prefijo;
        this.datosEncabezado.concepto = this.documentoEncabezado.concepto;
        this.datosEncabezado.moneda = this.documentoEncabezado.moneda;
      }
    } else {
      this.validarEncabezado();
      this.datosEncabezado.fecha = this.documentoEncabezado.fecha;
      this.datosEncabezado.prefijo = this.documentoEncabezado.prefijo;
      this.datosEncabezado.moneda = this.documentoEncabezado.moneda;
      if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
      this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
      if (this.documentoEncabezado.empresa || this.documentoEncabezado.terceroExterno || this.documentoEncabezado.terceroInterno) {
        this.datosEncabezado.empresa = this.documentoEncabezado.empresa ? this.documentoEncabezado.empresa : {};
        this.datosEncabezado.fecha = this.documentoEncabezado.fecha;
        this.datosEncabezado.direccion = this.documentoEncabezado.direccionTerceroExterno;
        this.datosEncabezado.terceroExterno = this.documentoEncabezado.terceroExterno;
        this.datosEncabezado.terceroInterno = this.documentoEncabezado.terceroInterno;
        this.datosEncabezado.formaPago = this.documentoEncabezado.formaPago;
        this.datosEncabezado.concepto = this.documentoEncabezado.concepto;
      }
    }
    this.documentoEncabezadoCopia = { ...this.documentoEncabezado };
    this.atributosEncabezado = this.documentoEncabezado.atributosEncabezado;
    this.validacionBotonMas = this.getValidacionBotonMas();
    this._LoaderService.quitarMascara('panel/punto-de-venta', 'tabla', 'loadingIndex', 'paneTabs');
  }

  ngAfterViewInit() {
    this.inicializarFiltros();
  }

  esperarPrefijoOnline() {
    if (sessionStorage.getItem('prefijoOnline') === null) {
      this.datosEncabezado.prefijo = this.terminal.prefijoOffline;
      this.documentoEncabezado.prefijo = this.terminal.prefijoOffline;
      if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
      this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
    }
  }

  hayRenglonesClick() {
    if (this.disableBox) {
      this.verPopup('noEditablePorRenglones', false, '', SeveridadEnum.ERROR);
    }
  }

  verPopup(codigoError, conBoton: boolean, opciones, severidad, eliminar?, botonSiNo?) {
    this.esperarRespuesta();
    if (Array.isArray(opciones)) {
      this.popUpService.open({ codigoError, severidad, conBoton, detalle: '', detalles: opciones, eliminar, botonSiNo, inputs: { idPopup: this.errorPopUp } });
    } else {
      this.popUpService.open({ codigoError, severidad, conBoton, detalle: opciones, eliminar, botonSiNo });
    }
  }

  esperarRespuesta() {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup') {
          this.respuestaPopup(respuesta.event);
        }
      }
    )
  }

  respuestaPopup(e: any) {
    if (e === true) {
      if (this.onlineOffline) {
        let mensaje = {
          clase: 'DocumentoEncabezado',
          id: this.idDocumento,
          accion: 'editarDocumentoEncabezado',
          atributo: 'fecha',
          valor: this.fechaCambio,
          prioridad: true,
          tipoDato: TipoDato[TipoDato.FECHA],
          permisoGrueso: this.tipoDocumento
        };
        const variable = this.serviceColas.agregarACola(mensaje);
        if (variable) {
          variable.pipe(take(1)).subscribe(response => {
            if (response && response[0] && !response[0].codigoError) {
              this.cambioDatosEncabezado.emit(this.datosEncabezado);
            }
          });
        }
      } else {
        this.documentoEncabezado.fecha = this.fechaCambio;
        this.datosEncabezado.fecha = this.fechaCambio;
        if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
        this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
        setTimeout(() => {
          this.cambioDatosEncabezado.emit(this.datosEncabezado);
        }, 500);
      }
    } else if (e === false) {
      this.documentoEncabezado.fecha = this.fechaAnterior;
      this.datosEncabezado.fecha = this.fechaAnterior;
      if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
      this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
      setTimeout(() => {
        this.cambioDatosEncabezado.emit(this.datosEncabezado);
      }, 500);
    }
  }

  validarEncabezado() {
    if (
      this.datosEncabezado.empresa &&
      this.datosEncabezado.fecha &&
      this.datosEncabezado.direccion &&
      this.datosEncabezado.terceroExterno &&
      this.datosEncabezado.terceroInterno &&
      this.datosEncabezado.formaPago &&
      this.datosEncabezado.prefijo.id != 1 &&
      this.datosEncabezado.concepto &&
      this.datosEncabezado.moneda
    ) {
      sessionStorage.setItem('EncabezadoCompleto', 'true');
      this.temporalEncabezado = this.datosEncabezado;
    }
  }

  cargarDireccionesEmitter() {
    this.direccionCambio;
  }

  async cambio(atributo, valor, accion) {
    //capturamos el cambio de tercero para editar el estado de la variable validaUvt
    let valUvt = localStorage.getItem('validaUvt');
    if (atributo == 'terceroExterno' && valor.terceroTipos.filter(tercero => tercero.nombre == 'Cliente') && valUvt == 'true') {
      localStorage.setItem('validaUvt', 'false');
    }
    if (this.onlineOffline && this.documentoEncabezado.enLinea && !this.idDocumento && !this.datosEncabezado.terceroExterno.pendienteCreacion) {
      this.documentoEncabezado = await this.productosDBService.getDocumentoencabezado(this.parametros.params);
      this.idDocEnc = this.documentoEncabezado.encabezadoId;
      this.idDocumento = this.documentoEncabezado.encabezadoId;
    }

    if (atributo === 'terceroExterno' && this.onlineOffline && this.documentoEncabezado.enLinea && !this.datosEncabezado.terceroExterno.pendienteCreacion) {
      this.idGlobal = valor.id;
      sessionStorage.setItem('idGlobalTercero', String(this.idGlobal));
    }
    let valido = true;
    this.fechaAnterior = this.documentoEncabezado.fecha;
    if (atributo === 'empresa') {
      this.documentoEncabezado.empresa = this.datosEncabezado.empresa;
      if (this.tipoDocumento != DocumentoTipoEnum.DEPRECIACION) {
        if (this.filtrosDocumentoAsociado) {
          this.filtrosDocumentoAsociado = this.filtrosDocumentoAsociado.filter(ev => ev.atributo != 'empresa.id')
          this.filtrosDocumentoAsociado.push(this.getFiltroEmpresa(valor.id));
        }
      }
    } else if (atributo === 'direccion') {
      this.valorActualDireccion = Object.assign({}, this.direccionElegida);
      const cliente = await this.productosDBService.getListTerceroId(this.datosEncabezado.terceroExterno.id);
      if (cliente.direccion === undefined || cliente.direccion === null) {
        cliente.direccion = [];
        cliente.direccion.push(valor.direccion);
        this.documentoEncabezado.direccionTerceroExterno = cliente.direccion;
        this.productosDBService.setUpdateTercero(cliente);
        if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
        this.productosDBService.setDocumentoencabezado({
          id: this.idDocumento,
          direccion: valor
        });
      }
      this.direccionElegida = valor;
      if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
      this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
    } else if (atributo == 'terceroInterno') {
      this.datosEncabezado.terceroInterno = valor;
      this.documentoEncabezado.terceroInterno = this.datosEncabezado.terceroInterno;
      if (this.onlineOffline && this.documentoEncabezado.enLinea) this.enviar(accion, valor, atributo);
      if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
      this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
      this.validarEncabezado();
      this.idVendedor = this.vendedor.id;
    } else if (atributo == 'fecha') {
      if (this.onlineOffline && this.documentoEncabezado.enLinea) {
        this.validarFechaFutura(accion, valor, atributo);
      } else {
        this.fechaCambio = valor;
        const hoy = new Date(Date.now());
        let formattedDate = this.datepipe.transform(hoy, 'yyyy-MM-dd');
        if (formattedDate < valor) {
          this.verPopup('180', true, '', 3);
        }
      }
      this.documentoEncabezado.fecha = this.datosEncabezado.fecha;
      if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
      this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
      valido = false;
      setTimeout(() => {
        this.cambioDatosEncabezado.emit(this.documentoEncabezado);
      }, 500);
    } else if (atributo === 'formaPago') {
      this.documentoEncabezado.formaPago = this.datosEncabezado.formaPago;
      sessionStorage.setItem('formaPago', this.datosEncabezado.formaPago.nombre);
    } else if (atributo === 'concepto') {
      this.documentoEncabezado.concepto = this.datosEncabezado.concepto;
    }

    if (atributo != 'Actualizar') {
      if (atributo === 'prefijo') this.prefijoSeleccionado = valor;
      if (valido) {
        if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
        if (this.onlineOffline && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando) {
          this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
          this.enviar(accion, valor, atributo);
          this.validarEncabezado();
        } else {
          this.documentoEncabezado.enLinea = false;
          if (atributo === 'terceroExterno') {
            this.cambioTerceroAsignarDireccion(valor);
          } else {
            this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
            setTimeout(() => {
              this.cambioDatosEncabezado.emit(this.documentoEncabezado);
            }, 500);
            this.validarEncabezado();
          }
        }
      }
    }
  }

  validarFechaFutura(accion, valor, atributo) {
    let mensaje = {
      clase: 'DocumentoEncabezado',
      id: this.idDocumento,
      accion: 'fechaFuturaDocumento',
      atributo: 'fecha',
      valor: valor,
      prioridad: true,
      tipoDato: TipoDato[TipoDato.FECHA],
      permisoGrueso: this.tipoDocumento
    };
    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.pipe(take(1)).subscribe(response => {
        if (response && response[0] != null) {
          if (response[0].codigoError) {
            this.verPopup(response[0].codigoError, true, '', response[0].severidad);
            this.mensajeFecha = true;
            this.fechaCambio = valor;
            this.fechaAnterior = response[0].valor;
          }
        } else {
          if (this.onlineOffline) {
            this.enviar(accion, valor, atributo);
          } else {
            this.documentoEncabezado.enLinea = false;
            this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
            setTimeout(() => {
              this.cambioDatosEncabezado.emit(this.documentoEncabezado);
            }, 500);
          }
        }
      });
      this.subscriptions.add(sub);
    }
  }

  getFiltroTerceroInterno(): FiltroWo {
    return new FiltroWo('codigo', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [TipoTercero.VENDEDOR], 'terceroTipos', Operador.AND);
  }

  /**
   * getFiltroTerceroExterno: retorna el filtro del tipo de tercero segun el documento
   * en el que esta el usuario
   */
  getFiltroTerceroExterno(): FiltroWo {
    return new FiltroWo('codigo', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [TipoTercero.CLIENTE], 'terceroTipos', Operador.AND);
  }

  /**
   * getFiltroFormaPago: retorna un filtro para que se muestren solamente las formas de pago configurados para
   * el documento en el que se encuentra el usuario
   */
  getFiltroFormaPago(): FiltroWo {
    return new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.tipoDocumento], 'documentoTipos', Operador.AND);
  }
  getFiltroFormaPagoSenDisponible(): FiltroWo {
    return new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, '', Operador.AND);
  }

  /**
   * getFiltroFormaPago: retorna un filtro para que se muestren solamente las formas de pago configurados para
   * el documento en el que se encuentra el usuario
   */
  getFiltroFormaPagoClasificacion(): FiltroWo {
    return new FiltroWo('formaPagoClasificacion.id', '', null, TipoFiltro.CONTIENE, TipoDato.IN, null, [FormaPagoClasificacionEnum.CONTABILIZACION, FormaPagoClasificacionEnum.CONTABILIZACION_PAGO], 'FormaPago', Operador.AND);
  }

  /**enviar
   * getFiltroPrefijo: retorna un filtro para que se muestren solamente los prefijos configurados para
   * el documento en el que se encuentra el usuario
   */
  getFiltroPrefijo(): FiltroWo {
    return new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.tipoDocumento], 'documentoTipoes', Operador.AND);
  }

  /**
   * getFiltroPrefijoVacio: filtra los prefijos, para que no se muestre el prefijo vacio.
   */
  getFiltroPrefijoVacio(): FiltroWo {
    return new FiltroWo('descripcion', '', null, TipoFiltro.IGUAL, TipoDato.NOT_IN, null, ['VACIO'], 'Prefijo', Operador.AND);
  }

  /**
   * getFiltroPrefijoActivo: filtra los prefijos que se encuentran activos.
   */
  getFiltroPrefijoActivo(): FiltroWo {
    return new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Prefijo', Operador.AND);
  }

  /**
 * getFiltroTerceroDisponible: filtra por los terceros que se encuentran disponibles
 */
  getFiltroTerceroDisponible(): FiltroWo {
    return new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND);
  }

  /**
   * getFiltroTerceroActivo: filtra por los terceros que se encuentran activos
   */
  getFiltroTerceroActivo(): FiltroWo {
    return new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND);
  }

  /**
   * getFiltroMotivoDevolucion: retorna un filtro para que se muestren solamente los motivos de devolución configurados para
   * el documento en el que se encuentra el usuario
   */
  getFiltroMotivoDevolucion(tipoDocumento): FiltroWo {
    return new FiltroWo('codigoDocumento', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [tipoDocumento], 'documentoTipos', Operador.AND
    );
  }

  /**
   * getUsuarioLogueado: retorna un filtro del usuario que se encuentra logueado
   */
  getUsuarioLogueado(): FiltroWo {
    return new FiltroWo('id', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [this.usuario], 'usuarios', Operador.AND);
  }

  /**
   * getFiltroDocumentoAsociado: retorna del documento asociado
   */
  getFiltroDocumentoAsociado(): FiltroWo {
    return null;
  }

  /**
   * getFiltroFechaDocumentoAsociado: retorna el filtro de fecha para la lista de documentos asociado
   */
  getFiltroFechaDocumentoAsociado(): FiltroWo {
    return null;
  }

  /**
   * getFiltroEmpres: retorna el filtro de empresa para la lista de documentos asociado
   */
  getFiltroEmpresa(id): FiltroWo {
    return new FiltroWo('empresa.id', `${id}`, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'documentoEncabezado', Operador.AND);
  }

  /**
   * getFiltroDocumentoNoContabilizado: retorna del documento asociado
   */
  getFiltroDocumentoNoContabilizado(): FiltroWo {
    return null;
  }
  getFiltroTerceroCliente(id) {
    return new FiltroWo('terceroExterno.id', id, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'documentoEncabezado', Operador.AND);
  }
  getFiltroMonedaSenDisponible() {
    return new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Moneda', Operador.AND);
  }
  getFiltroPaisLocal(): FiltroWo {
    return new FiltroWo('ubicacionPais.id', this.paisLocal.id, null, TipoFiltro.IGUAL, TipoDato.LONG, null, null, 'ubicacionPais', Operador.AND);
  }

  inicializarFiltros(): void {
    this.filtroMonedaDisponible.push(this.getFiltroMonedaSenDisponible())
    this.filtrosEmpresa.push(this.getUsuarioLogueado());
    this.filtrosTerInterno.push(this.getFiltroTerceroDisponible());
    this.filtrosTerInterno.push(this.getFiltroTerceroActivo());
    let filtrosTerInterno = this.getFiltroTerceroInterno();
    if (filtrosTerInterno) {
      this.filtrosTerInterno.push(filtrosTerInterno);
    }
    this.filtrosTerExterno.push(this.getFiltroTerceroDisponible());
    this.filtrosTerExterno.push(this.getFiltroTerceroActivo());
    let filtrosTerExterno = this.getFiltroTerceroExterno();
    if (filtrosTerExterno) {
      this.filtrosTerExterno.push(filtrosTerExterno);
    }
    this.filtrosFormaPago.push(this.getFiltroFormaPago());
    this.filtrosFormaPago.push(this.getFiltroFormaPagoClasificacion());
    this.filtrosFormaPago.push(this.getFiltroFormaPagoSenDisponible());
    this.filtrosPrefijo.push(this.getFiltroPrefijoVacio());
    this.filtrosPrefijo.push(this.getFiltroPrefijo());
    this.filtrosPrefijo.push(this.getFiltroPrefijoActivo());
    this.filtrosMotivoDevolucion.push(this.getFiltroPaisLocal());
    this.filtrosMotivoDevolucion.push(this.getFiltroMotivoDevolucion(this.tipoDocumento));

  }

  cargarListaDocumentoAsociado() {
    const PAGINACION = this.getPaginacionDocumentosAsociados(this.filtrosDocumentoAsociado);
    let sub = this.serviceDocumentoEncabezado.getDocumentosNoAnulados(["documentoTipo.nombreDocumento", "prefijo.nombre", "numero", "fecha"], PAGINACION).subscribe(
      response => {
        this.listaDocumentosAsociados = response.content;
        if (this.tipoDocumento == DocumentoTipoEnum.NOTA_DEBITO_VENTA || this.tipoDocumento == DocumentoTipoEnum.NOTA_CREDITO_VENTA) {
          this.listaDocumentosAsociados.unshift(this.documentoExternoOpcion);
        }
      });
    this.subscriptions.add(sub);
  }

  private getPaginacionDocumentosAsociados(filtrosAplicados?: FiltroWo[]) {
    const paginacion = new PaginacionWo('');
    paginacion.columnaOrdenar = 'fecha,prefijo.nombre,numero';
    paginacion.orden = 'DESC'
    paginacion.pagina = 0;
    paginacion.registrosPorPagina = 1000000;
    paginacion.filtros = filtrosAplicados;

    return paginacion;
  }

  /**
   * Obtener las Empresas
   */
  /*   async getEmpresas() {
      if (this.onlineOffline) {
        const idUsuario = new Number(sessionStorage.getItem('USUARIOSESION'));
        const paginacionEmpresa = new PaginacionWo('id', 0, 10, 'ASC');
        this.posService.getEmpresas(idUsuario, paginacionEmpresa).subscribe(data => {
          this.dataEmpresa = [];
          for (const item of data.content) {
            const empresa = {
              id: `${item.id}`,
              nombre: item.nombre,
            }
            this.dataEmpresa.push(empresa);
            this.productosDBService.setListEmpresa(item);
          }
        });
      } else {
        this.dataEmpresa = [];
        const listaEmpresa: any = await this.productosDBService.getEmpresaLocal();
        for (const item of listaEmpresa) {
          const empresa = {
            id: `${item.id}`,
            nombre: item.nombre,
          }
          this.dataEmpresa.push(empresa);
        }
      }
    }
  
    async filtrarEmpresas(event) {
      let listaEmpresas = [];
      if (this.onlineOffline) {
        const usuario = new Number(sessionStorage.getItem('USUARIOSESION'));
        const paginacionEmpresa = new PaginacionWo('id', 0, 10, 'ASC');
        this.posService.getEmpresas(usuario, paginacionEmpresa).subscribe(data => {
          for (const item of data.content) {
            if (item.nombre.toLowerCase().includes(event.target.value)) {
              const empresa = {
                id: `${item.id}`,
                nombre: item.nombre,
              }
              listaEmpresas.push(empresa);
              this.productosDBService.setListEmpresa(item);
            }
          }
          this.dataEmpresa = listaEmpresas;
        });
      } else {
        const listaEmpresa: any = await this.productosDBService.getEmpresaLocal();
        const listaEmpresa2 = [];
        for (const item of listaEmpresa) {
          if (item.nombre.toLowerCase().includes(event.target.value)) {
            const empresa = {
              id: `${item.id}`,
              nombre: item.nombre,
            }
            listaEmpresa2.push(empresa);
          }
        }
        this.dataEmpresa = listaEmpresa2;
      }
    } */

  /**
   * Obtener la Moneda
   */
  async ObtenerMoneda() {
    const paginacionMoneda = new PaginacionWo('codigo', 0, 150, 'ASC', []);
    if (this.onlineOffline === true) {
      const listMoneda = await this.productosDBService.getMonedaLocal();
      this.posService.getMoneda(paginacionMoneda).subscribe(data => {
        this.dataMoneda = data.content;
        const moneda = new Moneda();
        for (const item of this.dataMoneda) {
          moneda.id = item.id;
          moneda.codigo = item.codigo;
          moneda.nombre = item.nombre;
          moneda.cantidadDecimales = item.cantidadDecimales;
          moneda.separadorMiles = item.separadorMiles;
          moneda.simbolo = item.simbolo;
          moneda.separadorDecimales = item.separadorDecimales;
          this.listaMoneda.push(moneda);
          if (!listMoneda.find(f => f.id == item.id)) {
            this.productosDBService.setListMoneda(moneda);
          }
        }
      });
    } else {
      const listaMoneda = await this.productosDBService.getMonedaLocal();
      this.dataMoneda = listaMoneda;
    }
  }

  async obtenerTercero(tipoTercero, buscarTercero?) {
    this.listaDirecciones = [];
    this.datosEncabezado.direccion = [];
    this.listaClient = [];
    this.listaCliente2 = [];
    this.listaVendedor = [];
    this.listaVendedor2 = [];
    this.direccionCambio = [];
    if (this.onlineOffline) {
      this.ObtenerTerceroPos(tipoTercero, buscarTercero);
    } else {
      const listTer = await this.productosDBService.getTerceroLocal();
      const listaDatos = listTer.filter(tercero => tercero.terceroTipos.find(f => f.nombre === tipoTercero));
      listaDatos.forEach(tercer => {
        tercer.nombreTerceroTipoIdentificacion = tercer.terceroTipoIdentificacion ? tercer.terceroTipoIdentificacion.abreviatura : '';
        tercer.nombreUbicacionCiudad = tercer.ubicacionCiudad ? tercer.ubicacionCiudad.nombre : '';
        tercer.nombreTerceroTipoContribuyente = tercer.terceroTipoContribuyente ? tercer.terceroTipoContribuyente.nombre : '';
        tercer.nombreTerceroClasificacionAdministradorImpuesto = tercer.terceroClasificacionAdministradorImpuestoPojo ? tercer.terceroClasificacionAdministradorImpuestoPojo.nombre : '';
        if (!this[tipoTercero == 'Cliente' ? 'listaCliente2' : 'listaVendedor2'].some(e => e.id == tercer.id)) this[tipoTercero == 'Cliente' ? 'listaCliente2' : 'listaVendedor2'].push(tercer);
      });

      this[tipoTercero == 'Cliente' ? 'dataCliente' : 'dataVendedor'] = tipoTercero == 'Cliente' ? [...this.listaCliente2] : [...this.listaVendedor2];

      if (buscarTercero) {
        this.filtroTercero(tipoTercero, tipoTercero == 'Cliente' ? [...this.listaCliente2] : [...this.listaVendedor2], buscarTercero);
      } else {
        this[tipoTercero == 'Cliente' ? 'listaClient' : 'listaVendedor'] = this[tipoTercero == 'Cliente' ? 'listaCliente2' : 'listaVendedor2'];
      }
    }
  }

  busquedaInput(event, filtrar) {
    this.debounceTimeBusqueda.next({ event, filtrar });
  }

  filtroTercero(tipoTercero, listaTerceros, event) {
    if (event.filtros && event.filtros[0] && event.filtros[0].valor !== '') {
      const textoBuscado = event.filtros[0].valor;
      this.listaCliente2 = [];
      this.listaVendedor2 = [];
      const columnasVisibles = ['nombreCompleto', 'nombreTerceroTipoIdentificacion', 'identificacion', 'nombreUbicacionCiudad', 'nombreTerceroTipoContribuyente', 'nombreTerceroClasificacionAdministradorImpuesto'];

      listaTerceros.forEach(item => {
        columnasVisibles.forEach((columna, i) => {
          if (item[columna] && item[columna].toLowerCase().includes(textoBuscado) && !this[tipoTercero == 'Cliente' ? 'listaCliente2' : 'listaVendedor2'].some(e => e.id == item.id)) this[tipoTercero == 'Cliente' ? 'listaCliente2' : 'listaVendedor2'].push(item);
        });
      });
      this[tipoTercero == 'Cliente' ? 'listaClient' : 'listaVendedor'] = this[tipoTercero == 'Cliente' ? 'listaCliente2' : 'listaVendedor2'];
    } else {
      this[tipoTercero == 'Cliente' ? 'listaClient' : 'listaVendedor'] = tipoTercero == 'Cliente' ? [...this.dataCliente] : [...this.dataVendedor];
    }
  }

  asignarVendedorOnline(item) {
    const vendedor = new Tercero();
    vendedor.id = item.id;
    vendedor.identificacion = item.identificacion;
    vendedor.nombreCompleto = item.nombreCompleto;
    vendedor.primerApellido = item.primerApellido;
    vendedor.primerNombre = item.primerNombre;
    vendedor.segundoApellido = item.segundoApellido;
    vendedor.segundoNombre = item.segundoNombre;
    const identificacion = new TerceroTipoIdentificacion();
    identificacion.abreviatura = item.terceroTipoIdentificacion && item.terceroTipoIdentificacion.abreviatura ? item.terceroTipoIdentificacion.abreviatura : item["terceroTipoIdentificacion.abreviatura"];
    vendedor.terceroTipoIdentificacion = identificacion;
    vendedor.terceroTipos = item.terceroTipos;
    let vended = [];
    vended["terceroTipoIdentificacion.abreviatura"] = vendedor.terceroTipoIdentificacion.abreviatura;
    vended["nombreCompleto"] = vendedor.nombreCompleto;
    vended["identificacion"] = vendedor.identificacion;
    vended["id"] = vendedor.id;
    return { vended, vendedor };
  }

  asignarVendedorOffline(item) {
    const vendedor = new Tercero();
    vendedor.id = item.id;
    vendedor.identificacion = item.identificacion;
    vendedor.nombreCompleto = item.nombreCompleto;
    const identificacion = new TerceroTipoIdentificacion();
    identificacion.abreviatura = item.terceroTipoIdentificacion.abreviatura;
    vendedor.terceroTipoIdentificacion = identificacion;
    let vended = [];
    vended["terceroTipoIdentificacion.abreviatura"] = item.terceroTipoIdentificacion.abreviatura;
    vended["nombreCompleto"] = vendedor.nombreCompleto;
    vended["identificacion"] = vendedor.identificacion;
    vended["id"] = vendedor.id;
    return { vended, vendedor };
  }

  async cargarDireccionCliente(clienteNuevo) {
    this.datosEncabezado.direccion = null;
    this.datosEncabezado.direccionTerceroExterno = null;
    this.documentoEncabezado.direccionTerceroExterno = null;
    if (this.onlineOffline && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando) {
      const paginacionDirecciones = new PaginacionWo('id', 0, 10, 'ASC');
      this.posService.getDirecciones(paginacionDirecciones, clienteNuevo).subscribe(async dataEm => {
        if (dataEm && dataEm.content && dataEm.content[0]) {
          const direccionPrincipal = dataEm.content.length > 1 ? dataEm.content.find(dir => dir.senPrincipal) : dataEm.content[0];
          this.documentoEncabezado.direccionTerceroExterno = direccionPrincipal;
          this.datosEncabezado.direccion = direccionPrincipal;
          this.datosEncabezado.direccionTerceroExterno = direccionPrincipal;
          this.documentoEncabezado.direccionTerceroExterno = direccionPrincipal;

          const cliente = await this.productosDBService.getListTerceroId(clienteNuevo);
          cliente.direccion = dataEm.content;
          this.productosDBService.setUpdateTercero(cliente);
          if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
          this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
          setTimeout(() => {
            this.cambioDatosEncabezado.emit(this.documentoEncabezado);
          }, 500);
        } else {
          this.terceroSinDireccion(this.datosEncabezado.terceroExterno.id);
        }
      });
    } else {
      const cliente = await this.productosDBService.getListTerceroId(clienteNuevo);
      if (cliente && cliente.direccion && (!Array.isArray(cliente.direccion) || (cliente.direccion[0] && cliente.direccion[0].direccion))) {
        let direccion;
        if (Array.isArray(cliente.direccion)) {
          direccion = cliente.direccion.length > 1 ? cliente.direccion.find(dir => dir.senPrincipal) : cliente.direccion[0];
        } else {
          direccion = cliente.direccion;
        }
        this.cliente = cliente;
        this.clienteValido = Object.assign(this.cliente);
        this.datosEncabezado.terceroExterno = this.cliente;
        this.documentoEncabezado.terceroExterno = this.cliente;
        this.datosEncabezado.direccion = direccion;
        this.documentoEncabezado.direccion = direccion;
        this.datosEncabezado.direccionTerceroExterno = this.datosEncabezado.direccion;
        this.documentoEncabezado.direccionTerceroExterno = this.datosEncabezado.direccion;
        if (cliente.terceroVendedorPredeterminado) {
          this.datosEncabezado.terceroInterno = cliente.terceroVendedorPredeterminado;
          this.documentoEncabezado.terceroInterno = cliente.terceroVendedorPredeterminado;
        }
        if (cliente.formaPagoPredeterminada) {
          this.datosEncabezado.formaPago = cliente.formaPagoPredeterminada;
          this.documentoEncabezado.formaPago = cliente.formaPagoPredeterminada;
        }
        if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
        this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
        setTimeout(() => {
          this.cambioDatosEncabezado.emit(this.documentoEncabezado);
        }, 500);
        this.validarEncabezado();
      } else {
        this.terceroSinDireccion(this.datosEncabezado.terceroExterno.id);
      }
    }
  }

  terceroSinDireccion(idTerceroSinDireccion) {
    const resp = this.popUpService.respuesta.subscribe(
      (respuesta) => {
        resp.unsubscribe();
        if (respuesta.tipo == 'respuestaPopup' && respuesta.event) {
          this.posService.crearDireccion.next(idTerceroSinDireccion);
        } else {
          const clienteAnterior = { ...this.documentoEncabezadoCopia.terceroExterno };
          this.cliente = clienteAnterior;
          this.clienteValido = Object.assign(this.cliente);
          const direccionAnterior = this.documentoEncabezadoCopia.direccionTerceroExterno ? { ...this.documentoEncabezadoCopia.direccionTerceroExterno } : this.documentoEncabezadoCopia.direccion;
          this.datosEncabezado.direccion = direccionAnterior;
          this.documentoEncabezado.direccionTerceroExterno = direccionAnterior;
          this.datosEncabezado.direccionTerceroExterno = direccionAnterior;
          this.documentoEncabezado.direccionTerceroExterno = direccionAnterior;
          this.datosEncabezado.terceroExterno = clienteAnterior;
          this.documentoEncabezado.terceroExterno = clienteAnterior;
          if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
          this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
          setTimeout(() => {
            this.cambioDatosEncabezado.emit(this.documentoEncabezado);
          }, 500);
          this.validarEncabezado();
        }
      });
    this.popUpService.open({ codigoError: 'direccionNoExisteDeseaCrear', severidad: SeveridadEnum.ERROR, conBoton: true, botonSiNo: true });
  }

  /**
   * Obtener Formas de pago
   * @constructor
   */
  async ObtenerFormasDePago(filtroBusqueda?) {
    if (filtroBusqueda) {
      console.log('filtroBusqueda:', filtroBusqueda)
      if (filtroBusqueda.filtros && filtroBusqueda.filtros[0] && filtroBusqueda.filtros[0].valor !== '') {
        const textoBuscado = filtroBusqueda.filtros[0].valor;
        this.dataFormaDePago = [];
        const columnasVisibles = ['nombre'];
        const dataFiltrada = [];

        this.dataTodasFormaDePago.forEach(item => {
          columnasVisibles.forEach((columna, i) => {
            if (item[columna] && item[columna].toLowerCase().includes(textoBuscado)) dataFiltrada.push(item);
          });
        });
        this.dataFormaDePago = dataFiltrada;
      } else {
        this.dataFormaDePago = [...this.dataTodasFormaDePago];
      }
    } else {
    this.filtroFormasDePago = new FiltroWo('codigoDocumento', '', null, 0, 5, null, [this.tipoDocumento], 'documentoTipos', 0, 'filtro');
    this.filtroFormasDePago2 = new FiltroWo('formaPagoClasificacion.id', '', null, 1, 6, null, [FormaPagoClasificacionEnum.CONTABILIZACION, FormaPagoClasificacionEnum.CONTABILIZACION_PAGO], 'FormaPago', 0, 'filtro');
    this.filtroFormasDePago3 = new FiltroWo('senDisponible', 'true', null, 0, 1, null, null, '', 0, 'filtro');
    const paginacionFormaDePago = new PaginacionWo('nombre', 0, 100, 'ASC', [this.filtroFormasDePago, this.filtroFormasDePago2, this.filtroFormasDePago3]);
    if (this.onlineOffline) {
      this.posService.getFormasDePago(paginacionFormaDePago).subscribe(async data => {
        const listTer = await this.productosDBService.getListTerceroId(this.idGlobal);
        const dataContent = data.content;
        if (listTer && listTer.credito) {
          let cupoCredito;
          let array = [];
          /*  cupoCredito = parseInt(listTer.credito.cupo);
            if (cupoCredito === 0 || listTer.credito.cupo === null || listTer.credito.cupo === undefined) {
              array = data.content.slice(0, 1);
              data.content = array;
            } else {*/
          data.content = dataContent;
          /*   }*/
        }
        this.dataTodasFormaDePago = [...data.content];
        this.dataFormaDePago = data.content;
        this.dataFormaDePago.forEach(item => {
          const formas = new FormasDePago();
          formas.id = item.id;
          formas.nombre = item.nombre;
          this.productosDBService.setFormaDePago(formas);
        });
      });
    } else {
      this.dataTodasFormaDePago = await this.productosDBService.getFormasDePagoLocal();
      const listTer = await this.productosDBService.getListTerceroId(this.idGlobal);
        this.dataFormaDePago = [...this.dataTodasFormaDePago];
      }
    }
  }


  async filtradoFormasDePago(event) {
    this.filtroFormasDePago = new FiltroWo('codigoDocumento', '', null, 0, 5, null, [this.tipoDocumento], 'documentoTipos', 0, 'filtro');
    this.filtroFormasDePago2 = new FiltroWo('formaPagoClasificacion.id', '', null, 1, 6, null, ['1', '3'], 'FormaPago', 0, 'filtro');
    this.filtroFormasDePago3 = new FiltroWo('senDisponible', 'true', null, 0, 1, null, null, '', 0, 'filtro');
    const paginacionFormaDePago = new PaginacionWo('nombre', 0, 10, 'ASC', [this.filtroFormasDePago, this.filtroFormasDePago2, this.filtroFormasDePago3]);
    const listaForma2 = [];
    if (this.onlineOffline) {
      const listaFormaPago = await this.productosDBService.getFormasDePagoLocal();
      this.posService.getFormasDePago(paginacionFormaDePago).subscribe(data => {
        this.dataFormaDePago = data.content;
        for (const item of this.dataFormaDePago) {
          if (item.nombre.toLowerCase().includes(event.target.value)) {
            const formas = new FormasDePago();
            formas.id = item.id;
            formas.nombre = item.nombre;
            listaForma2.push(formas);
            if (!listaFormaPago.find(f => f.id == formas.id)) {
              this.productosDBService.setFormaDePago(formas);
            }
          }
        }
        this.dataFormaDePago = listaForma2;
      });
    } else {
      const listaForma = await this.productosDBService.getFormasDePagoLocal();
      this.dataFormaDePago = listaForma;
      for (const item of listaForma) {
        if (item.nombre.toLowerCase().includes(event.target.value)) {
          const formas = new FormasDePago();
          formas.id = item.id;
          formas.nombre = item.nombre;
          listaForma2.push(formas);
          if (!listaForma.find(f => f.id == formas.id)) {
            this.productosDBService.setFormaDePago(formas);
          }
        }
      }
      this.dataFormaDePago = listaForma2;
    }
  }

  /**
   * Obtener los prefijos
   */
  async ObtenerPrefijo() {
    const terminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
    if (this.onlineOffline) {
      this.dataPrefijo = terminal.prefijos;
      for (const item of this.dataPrefijo) {
        const prefijo = new Prefijo();
        prefijo.id = item.id;
        prefijo.nombre = item.nombre;
      }
    } else {
      const temp = [];
      temp.push(terminal.prefijoOffline);
    }
  }

  async filtrarPrefijo(event) {
    const terminal = JSON.parse(sessionStorage.getItem('TERMINAL'));
    if (this.onlineOffline) {
      const dataPrefijoTemp = terminal.prefijos;
      const dataPrefijos2 = [];
      for (const item of dataPrefijoTemp) {
        if (item.nombre.toLowerCase().includes(event.target.value)) {
          const prefijo = new Prefijo();
          prefijo.id = item.id;
          prefijo.nombre = item.nombre;
          dataPrefijos2.push(prefijo);
        }
      }
      this.dataPrefijo = dataPrefijos2
    } else {
      const temp = [];
      temp.push(terminal.prefijoOffline);
    }
  }

  async ObtenerTerceroPos(tipoTercero, buscarTercero?, inicio?) {
    this.listaCliente2 = [];
    const paginacion = new PaginacionWo('id', 0, 0, 'DESC');
    paginacion.filtros = [new FiltroWo('senDisponible', 'true', null, 0, 1, null, null, 'terceroTipos', 0, 'filtro'), new FiltroWo('senActivo', 'true', null, 0, 1, null, null, 'terceroTipos', 0, 'filtro')];
    if (buscarTercero && buscarTercero.filtros) paginacion.filtros.push(new FiltroWo('busqueda', buscarTercero.filtros[0].valor, null, TipoFiltro.CONTIENE, TipoDato.STRING, null, [-1], 'Tercero', Operador.AND));
    paginacion.filtros.push(new FiltroWo('tipoTercero', '', null, TipoFiltro.CONTIENE, TipoDato.STRING, null, [tipoTercero == 'Cliente' ? TipoTercero.CLIENTE.toString() : TipoTercero.VENDEDOR.toString()], 'Tercero', Operador.AND));
    const url = buscarTercero ? 'getListaTerceroPosBusqueda' : 'getListaTerceroPos';
    if (!buscarTercero) this.productosDBService.deleteTercero();
    this.posService[url](paginacion).subscribe((resp: any) => {
      const data = buscarTercero ? resp.content : resp;
      data.forEach(item => {
        let tercer: any = new Tercero();
        tercer = { ...tercer, ...item };
        const listDireccion = [];
        listDireccion.push({ ...item.direccion });
        tercer.direccion = listDireccion;
        tercer.nombreTerceroTipoIdentificacion = tercer.terceroTipoIdentificacion ? tercer.terceroTipoIdentificacion.abreviatura : '';
        tercer.nombreUbicacionCiudad = tercer.ubicacionCiudad ? tercer.ubicacionCiudad.nombre : '';
        tercer.nombreTerceroTipoContribuyente = tercer.terceroTipoContribuyente ? tercer.terceroTipoContribuyente.nombre : '';
        tercer.nombreTerceroClasificacionAdministradorImpuesto = tercer.terceroClasificacionAdministradorImpuestoPojo ? tercer.terceroClasificacionAdministradorImpuestoPojo.nombre : '';
        if (!buscarTercero) this.productosDBService.setTercero(tercer);
        if (tercer.terceroTipos.find(f => f.nombre === tipoTercero)) {
          if (!this[tipoTercero == 'Cliente' ? 'listaCliente2' : 'listaVendedor2'].some(e => e.id == tercer.id)) this[tipoTercero == 'Cliente' ? 'listaCliente2' : 'listaVendedor2'].push(tercer);
        }
      });

      if (!inicio) {
        this[tipoTercero == 'Cliente' ? 'dataCliente' : 'dataVendedor'] = tipoTercero == 'Cliente' ? [...this.listaCliente2] : [...this.listaVendedor2];
        this[tipoTercero == 'Cliente' ? 'listaClient' : 'listaVendedor'] = this[tipoTercero == 'Cliente' ? 'listaCliente2' : 'listaVendedor2'];
      }

      this.totalPagTerceros = Math.ceil([tipoTercero == 'Cliente' ? 'listaClient' : 'listaVendedor'].length / this.cantidadElementos);
    });
  }

  async obternerDirecciones() {
    this.listaDirecciones = [];
    this.listaDirecciones2 = [];
    const existenTercerosPendientesSync = await this.productosDBService.getExistenTercerosPendientesSincronizar();
    if (this.onlineOffline && !existenTercerosPendientesSync) {
      const paginacionDirecciones = new PaginacionWo('id', 0, 0, 'ASC');
      this.posService.getDirecciones(paginacionDirecciones, this.datosEncabezado.terceroExterno.id).subscribe(dataEm => {
        this.listaDirecciones2 = dataEm.content;
        this.listaDirecciones = this.listaDirecciones2;
      });
    } else {
      const cliente = await this.productosDBService.getListTerceroId(this.datosEncabezado.terceroExterno.id);
      this.listaDirecciones2 = [...cliente.direccion];
      this.listaDirecciones = this.listaDirecciones2;
    }
  }

  conceptoCliente() {
    this.datosEncabezado.concepto = this.documentoEncabezado.concepto;
  }

  enviarEliminarDocumentoConDocumentoAsociado() {
    let mensaje = {
      id: this.idDocumento,
      accion: this.accionEliminarDocumentoConDocumentoAsociado,
      valor: '',
      clase: 'DocumentoEncabezado',
      prioridad: true,
      permisoGrueso: this.tipoDocumento,
      atributo: 'eliminar'
    }

    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.subscribe(response => {
        if (response != null) {
          if (response[0].codigoError) {
            alert('error en el eliminar documento');
          } else {
            let tab = {
              children: [],
              isCloseable: true,
              nombre: '',
              params: [],
              parent: null,
              queryParams: {},
              url: this.linkDocumento,
              textoAdicional: ''
            }
            this.tabsService.closeTab(tab, true);
            if (this.tipoDocumento == DocumentoTipo.DOCUMENTO_CANCELACION) {
              let asociatedTab = this.tabsService.findTab(DocumentoTipo.NOTA_CONTABILIDAD, +this.idDocumento + 1);
              if (asociatedTab) {
                this.tabsService.closeTab(asociatedTab, true);
              }
            } else if (this.tipoDocumento == DocumentoTipo.NOTA_CONTABILIDAD) {
              let asociatedTab = this.tabsService.findTab(DocumentoTipo.DOCUMENTO_CANCELACION, +this.idDocumento - 1);
              if (asociatedTab) {
                this.tabsService.closeTab(asociatedTab, true);
              }
            }
          }
        }
      });
    }
  }

  enviarAnularDocumentoConDocumentoAsociado() {

    let mensaje = {
      id: 249,
      accion: this.accionAnularDocumentoConDocumentoAsociado,
      valor: '',
      clase: 'DocumentoEncabezado',
      prioridad: true,
      permisoGrueso: this.tipoDocumento,
      atributo: 'eliminar'
    }

    let variable = this.serviceColas.agregarACola(mensaje);
    if (variable) {
      let sub = variable.subscribe(response => {
        if (response != null) {
          if (response[0].codigoError) {
            alert('error');
          } else {
            this.documentoEncabezado.concepto = 'ANULADA';
            this.direccionesCliente = [];
          }
        }
      });
    }
  }

  enviar(accion, valorInput, atributo) {
    let prioridad: boolean = true;
    let msjeAccion: string;
    let tipo = ['fecha'].includes(atributo) ? TipoDato[TipoDato.FECHA] : TipoDato[TipoDato.STRING];
    let valido = true;
    let valor = valorInput;
    if (accion) {
      if (['fechaFinal', 'fechaPago'].includes(atributo)) {
        msjeAccion = accion;
      } else {
        msjeAccion = this.getAccionAsociarTercero(accion);
        if (valor != undefined) {
          valor = valor.id;
        } else {
          valor = -1;
        }
      }
    }
    msjeAccion = msjeAccion ? msjeAccion : 'editarDocumentoEncabezado';
    if (['concepto', 'direccionTerceroExterno', 'terceroInterno'].includes(atributo)) {
      prioridad = true;
    } else if (atributo == 'trm') {
      tipo = TipoDato[TipoDato.NUMERIC];
      valor = valor.replace(/\,/gi, '.');
      this.documentoEncabezado.trm = valor;
      this.valorTRMnumeric = valor;
      prioridad = false;
    } else if (atributo == 'numero') {
      tipo = TipoDato[TipoDato.LONG];
      if (this.documentoEncabezado.numero == this.ultimoNumeroValido) {
        valido = false;
        this.documentoEncabezado.numero = this.ultimoNumeroValido;
      }
    }
    if (valido) {
      let mensaje = {
        clase: 'DocumentoEncabezado',
        id: this.idDocumento,
        accion: msjeAccion,
        atributo: atributo,
        valor: valor,
        prioridad: prioridad,
        tipoDato: tipo,
        permisoGrueso: this.tipoDocumento
      };
      let observable = this.serviceColas.agregarACola(mensaje);
      if (observable) {
        let sub = observable.subscribe(
          async response => {
            if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
            this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
            let tieneError = false;
            if (response.length > 0) {
              response.forEach(async res => {
                if (res.codigoError == '008') {
                  this.verPopup('008pos', false, '', SeveridadEnum.ERROR);
                  this.datosEncabezado.moneda = this.documentoEncabezado.moneda;
                }

                if (res.codigoError && !['108', '110', '159', '160', '187', '191', '192', '193', '226', '231', '23505'].includes(res.codigoError)) {
                  tieneError = true;
                  if (res && res.valor) this.responseCodigoError(res, atributo);
                  if (atributo == "fecha") {
                    this.datosEncabezado.fecha = this.fechaAnterior;
                    this.documentoEncabezado.fecha = this.fechaAnterior;
                  } else if (atributo == "fechaFinal") {
                    this.datosEncabezado.fechaFinal = this.fechaFinalAnterior;
                    this.documentoEncabezado.fechaFinal = this.fechaFinalAnterior;
                  } else if (atributo == "fechaPago") {
                    this.datosEncabezado.fechaPago = this.fechaPagoAnterior;
                    this.documentoEncabezado.fechaPago = this.fechaPagoAnterior;
                  }

                  this.verPopup(res.codigoError, false, '', res.severidad);
                } else {
                  if (res.codigoError) {
                    if (res.codigoError == '187') {
                      this.datosEncabezado.numero = res.valor;
                      this.documentoEncabezado.numero = res.valor;
                      this.verPopup(res.codigoError, false, '', SeveridadEnum.ERROR);
                      tieneError = true;
                    } else if (res.codigoError == '159') {
                      this.verPopup(res.codigoError, true, '', SeveridadEnum.ERROR);
                      res.atributo = '';
                      tieneError = true;
                    } else if (res.codigoError == '108' || res.codigoError == '110') {
                      this.verPopup(res.codigoError, false, res.valor + '.', SeveridadEnum.ERROR);
                      tieneError = true;
                    } else if (res.codigoError == '160') {
                      this.responseCodigoError(res, atributo);
                      tieneError = true;
                    } else if (res.codigoError == '23505' && (['numeroExterno', 'prefijoExterno', 'numero', 'prefijo'].includes(res.atributo))) {
                      this.responseCodigoError(res, atributo);
                      tieneError = true;
                    } else if (['191', '192', '193', '226'].includes(res.codigoError)) {
                      tieneError = true;
                      if (res.codigoError == '192') {
                        this.terceroSinDireccion(mensaje.valor);
                      } else {
                        this.cliente = res.valor;
                        this.clienteAnterior = Object.assign(this.cliente);
                        this.datosEncabezado.terceroExterno = res.valor;
                        this.documentoEncabezado.terceroExterno = res.valor;
                        this.verPopup(res.codigoError, false, '', SeveridadEnum.ERROR);
                      }
                    } else if (res.codigoError === '231') {
                      tieneError = true;
                      let valorAnteriorValido = res.valor.empresaAnterior;
                      this.datosEncabezado.empresa = valorAnteriorValido;
                      this.documentoEncabezado.empresa = valorAnteriorValido;
                    } else {
                      tieneError = true;
                      this.verPopup(res.codigoError, false, '', res.severidad);
                    }
                  } else if (res.codigoError == null) {
                    if (res.atributo == 'terceroExterno') {
                      this.cliente = res.valor;
                      this.clienteAnterior = Object.assign(this.cliente);
                      this.datosEncabezado.terceroExterno = res.valor;
                      this.documentoEncabezado.terceroExterno = res.valor;
                    } else if (res.atributo == 'terceroTipoContribuyente') {
                      this.cliente.terceroTipoContribuyente = res.valor.nombre;
                    } else if (res.valor) {
                      let resAtributo = res.atributo == 'vendedor' ? 'terceroInterno' : res.atributo == 'direccionTercero' ? 'direccionTerceroExterno' : res.atributo;
                      this.datosEncabezado[resAtributo] = res.valor;
                      this.documentoEncabezado[resAtributo] = res.valor;
                    }
                  }

                  if (res.atributo == 'vendedor') {
                    this.vendedor = res.valor;
                    this.verificarEncabezado();
                  } else if (res.atributo == 'formaPago') {
                    this.formaPago = res.valor != null ? res.valor : '';
                    this.datosEncabezado.formaPago = this.formaPago;
                    this.documentoEncabezado.formaPago = this.formaPago;
                  } else if (res.atributo == 'direccionTercero') {
                    this.direccionElegida = res.valor != null ? res.valor : '';
                    this.datosEncabezado.direccionTerceroExterno = this.direccionElegida;
                    this.documentoEncabezado.direccionTerceroExterno = this.direccionElegida;
                    this.valorActualDireccion = Object.assign({}, this.direccionElegida);
                    this.verificarEncabezado();
                  } else if (res.atributo == 'numero' && (res.codigoError == undefined || res.codigoError == null)) {
                    let doc = await this.productosDBService.getDocumentoencabezado(this.documentoEncabezado.id);
                    this.ultimoNumeroValido = res.valor;
                    this.datosEncabezado.numero = res.valor;
                    this.documentoEncabezado.numero = res.valor;
                    doc.numero = res.valor;
                    if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
                    this.productosDBService.setDocumentoencabezado(doc);
                  } else if (res.atributo == 'moneda') {
                    this.documentoEncabezado.trm = this.documentoEncabezado.trm = this.formatoUtilService.formatoMoneda(
                      res.valor, this.monedaLocal != undefined ? this.monedaLocal : this.moneda
                    );
                    this.datosEncabezado.trm = this.documentoEncabezado.trm;
                    this.moneda = res.valor;
                    this.valorAnteriorMoneda = Object.assign(this.moneda);
                  } else if (res.atributo == 'docAsociado') {
                    if (res.valor && res.valor.id == -10) {
                      let de = this.documentoExternoOpcion;
                      this.documentoAsociado = Object.assign(de);
                      this.documentoAsociadoCopia = Object.assign(de);
                    }
                  } else if (res.atributo == 'trm') {
                    this.documentoEncabezado.trm = this.formatoUtilService.formatoMoneda(
                      res.valor, this.monedaLocal != undefined ? this.monedaLocal : this.moneda
                    );
                    this.datosEncabezado.trm = this.documentoEncabezado.trm;
                    this.valorAnteriorMoneda = Object.assign(this.moneda);
                    this.valorTRMnumeric = res.valor;
                    this.verificarEncabezado();
                  }
                  setTimeout(() => {
                    this.cambioDatosEncabezado.emit(this.datosEncabezado);
                  }, 500);
                  if (this.borrador) this.comprobarBorrador();
                }
              });
            } else {
              if (atributo == 'trm') {
                this.datosEncabezado.trm = valor;
                this.documentoEncabezado.trm = valor;
                this.valorTRMnumeric = valor;
              } else if (atributo == 'direccion') {
                this.datosEncabezado.direccion = valorInput;
                this.datosEncabezado.direccionTerceroExterno = valorInput;
                this.documentoEncabezado.direccion = valorInput;
                this.documentoEncabezado.direccionTerceroExterno = valorInput;
              }

              if (this.borrador) this.comprobarBorrador();
            }

            if (!tieneError) {
              if (atributo == 'prefijoExterno') {
                this.prefijoExternoValido = valor;
              } else if (atributo == 'numeroExterno') {
                this.numeroExternoValido = valor;
              } else if (atributo == 'terceroExterno') {
                this.cliente = valor;
                this.clienteValido = Object.assign(this.cliente);
                this.cambioTerceroAsignarDireccion(valor);
                this.validarEncabezado();
              } else if (atributo == 'numero') {
                this.ultimoNumeroValido = valor;
              } else if (atributo == 'fecha') {
                this.fechaAnterior = valor;
              } else if (atributo == 'fechaFinal') {
                this.fechaFinalAnterior = valor;
              } else if (atributo == 'fechaPago') {
                this.fechaPagoAnterior = valor;
              }
              setTimeout(() => {
                this.documentoEncabezadoCopia = { ...this.documentoEncabezado };
                if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
                this.productosDBService.setDocumentoencabezado(this.datosEncabezado);
                this.cambioDatosEncabezado.emit(this.datosEncabezado);
              }, 500);
            } else {
              this.datosEncabezado = { ...this.documentoEncabezadoCopia };
              this.documentoEncabezado = { ...this.documentoEncabezadoCopia };
              sessionStorage.setItem('formaPago', this.datosEncabezado.formaPago.nombre);
              if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
              this.productosDBService.setDocumentoencabezado(this.datosEncabezado);
            }

          },
        );
        this.subscriptions.add(sub);
      }
    }
  }

  async cambioTerceroAsignarDireccion(valor) {
    const valorId = typeof valor === 'object' ? valor.id : valor;
    let filtroTerceroId = this.getFiltroTerceroCliente(valorId);
    this.filtrosTerDireccion = [filtroTerceroId];
    if (this.mostrarDocumentoAsociado) {
      this.filtrosDocumentoAsociado = [];
      this.filtrosDocumentoAsociado.push(this.getFiltroDocumentoAsociado());
      this.filtrosDocumentoAsociado.push(this.getFiltroFechaDocumentoAsociado());
      this.filtrosDocumentoAsociado.push(this.getFiltroDocumentoNoContabilizado());
      this.filtrosDocumentoAsociado.push(this.getFiltroEmpresa(this.documentoEncabezado.empresa.id));
      this.filtrosDocumentoAsociado.push(filtroTerceroId);
      this.cargarListaDocumentoAsociado();
    }
    const cliente = await this.productosDBService.getListTerceroId((valorId));
    if (this.onlineOffline && this.documentoEncabezado.enLinea && !this.documentoEncabezado.modificadoEnSync && !this.sincronizando) {
      this.posService.getListaDePrecios(valorId).subscribe(async (data: any) => {
        if (data && cliente.listaPrecioPojo === undefined) {
          const temporalPojo = [];
          temporalPojo.push(data.listaPrecioPredeterminada);
          cliente.listaPrecioPojo = temporalPojo;
          this.productosDBService.setUpdateTercero(cliente);
          this.datosEncabezado.terceroExterno = cliente;
          this.documentoEncabezado.terceroExterno = cliente;
        }
        this.datosEncabezado.terceroExterno = cliente;
        this.documentoEncabezado.terceroExterno = cliente;
        if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
        this.productosDBService.setDocumentoencabezado(this.documentoEncabezado);
      })
    }
    this.cargarDireccionCliente(valorId);
  }

  getAccionAsociarTercero(accion): any {
    if (accion == 'Tercero') {
      return 'asociar' + accion + 'DocumentoVentas';
    }
    return 'asociar' + accion + 'Documento';
  }

  verificarEncabezado() {
    if (this.validacionCamposEncabezado()) {
      this.tablaActiva = true;
      this.informacionRenglones.estaverificado = true;
      if (this.contabilizo) {
        if (!this.contabilizo) {
          this.informacionRenglones.estaContabilizado = false;
        }
      } else if (this.contabilizando) {
        if (!this.contabilizando) {
          this.informacionRenglones.estaContabilizado = false;
        }
      }
      if (this.estadoAnulado || this.estadoBloqueado || this.estadoAjustadoInventario) {
        this.informacionRenglones.estaverificado = false;
      }
    } else {
      this.documentoEncabezado.concepto == 'ANULADA' ? this.tablaActiva = true : this.tablaActiva = false;
      this.informacionRenglones.estaverificado = false;
    }
    this.informacionRenglones = Object.assign({}, this.informacionRenglones);
    this.pestanasBloqueadas = Object.assign([], this.pestanasBloqueadas);
  }

  validacionCamposEncabezado() {
    const camposComunes: any = this.getCamposComunes();
    const camposFe = this.getCamposFe(camposComunes);
    const camposMovimientoInventario = !!(camposComunes && this.formaPago);
    const camposNotaDebitoClientes = camposMovimientoInventario && this.idDocumentoAsociado && this.motivoDevolucion && this.estaDocumentoExterno == true;
    const camposNotaCreditoClientes = camposNotaDebitoClientes && this.clasificacionNotaCredito;
    const camposNotaDebitoProveedores = !!(camposMovimientoInventario && this.idDocumentoAsociado)
    const camposNotaCreditoProveedores = !!(camposNotaDebitoProveedores && this.clasificacionNotaCredito);
    if (this.tipoMovimiento == MovimientoTipoEnum.CONTABLE || this.tipoMovimiento == MovimientoTipoEnum.AJUSTE || this.tipoMovimiento == MovimientoTipoEnum.CONTABLE_INVENTARIO || this.tipoMovimiento == MovimientoTipoEnum.NOMINA) {
      this.encabezadoCompleto = camposComunes;
    } else if (this.mostrarDocumentoAsociado) {
      if (this.tipoDocumento == DocumentoTipoEnum.NOTA_CREDITO_VENTA) {
        this.encabezadoCompleto = camposNotaCreditoClientes;
      } else if (this.tipoDocumento == DocumentoTipoEnum.NOTA_DEBITO_VENTA) {
        this.encabezadoCompleto = camposNotaDebitoClientes;
      } else if (this.tipoDocumento == DocumentoTipoEnum.NOTA_DEBITO_COMPRA) {
        this.encabezadoCompleto = camposNotaDebitoProveedores;
      } else if (this.tipoDocumento == DocumentoTipoEnum.NOTA_CREDITO_COMPRA) {
        this.encabezadoCompleto = camposNotaCreditoProveedores;
      }
    } else if (this.botoneraRenglones.mostrarBotonFacturacionElectronica) {
      this.encabezadoCompleto = camposFe;
    } else {
      this.encabezadoCompleto = camposMovimientoInventario;
    }
    return this.encabezadoCompleto;
  }

  private getCamposFe(camposComunes: boolean | Tercero) {
    if (!this.estadoPresentadoElectronicamente) {
      return camposComunes && !this.estadoPresentadoElectronicamente;
    } else {
      return camposComunes && !this.estadoBloqueado && !this.estadoAjustadoInventario;
    }
  }

  private getCamposComunes() {
    if (this.tipoDocumento == DocumentoTipoEnum.SALDOS_INICIALES) {
      return this.documentoEncabezado.fecha && this.documentoEncabezado.numero && this.moneda && this.documentoEncabezado.empresa && this.cliente;
    } else if (this.tipoDocumento == DocumentoTipoEnum.DEPRECIACION) {
      return this.documentoEncabezado.fecha && this.documentoEncabezado.numero && this.documentoEncabezado.empresa;
    } else if (this.tipoDocumento == DocumentoTipoEnum.NOMINA_CONTABLE || this.tipoDocumento == DocumentoTipoEnum.DOCUMENTO_CANCELACION) {
      return this.documentoEncabezado.fecha && this.documentoEncabezado.numero && this.moneda && this.documentoEncabezado.empresa;
    } else if (this.tipoDocumento == DocumentoTipoEnum.AJUSTE_INVENTARIO) {
      return this.documentoEncabezado.fecha && this.documentoEncabezado.numero && this.moneda && this.documentoEncabezado.empresa && this.vendedor;
    } else if (this.tipoDocumento == DocumentoTipoEnum.COSTO_VENTA) {
      return this.documentoEncabezado.fecha && this.documentoEncabezado.numero && this.documentoEncabezado.empresa && this.cliente;
    } else if (this.tipoDocumento == DocumentoTipoEnum.RECIBO_CAJA || this.tipoDocumento == DocumentoTipoEnum.COMPROBANTE_EGRESO || this.tipoDocumento == DocumentoTipoEnum.NOTA_CONTABILIDAD) {
      return this.documentoEncabezado.fecha && this.documentoEncabezado.numero && this.documentoEncabezado.empresa && this.cliente;
    } else if (this.tipoDocumento == DocumentoTipoEnum.CONSIGNACION_BANCARIA || this.tipoDocumento == DocumentoTipoEnum.ENTRADA_PRODUCTO_TERMINADO || this.tipoDocumento == DocumentoTipoEnum.ENTRADA_ALMACEN || this.tipoDocumento == DocumentoTipoEnum.SALIDA_ALMACEN) {
      return this.documentoEncabezado.fecha && this.documentoEncabezado.numero && this.documentoEncabezado.empresa && this.cliente && this.moneda;
    } else if (this.tipoDocumento == DocumentoTipoEnum.NOMINA) {
      return this.documentoEncabezado.fecha && this.documentoEncabezado.fechaFinal && this.documentoEncabezado.numero && this.documentoEncabezado.empresa && this.documentoEncabezado.fechaPago;
    } else {
      return this.documentoEncabezado.fecha && this.moneda && this.documentoEncabezado.numero && this.documentoEncabezado.empresa && this.direccionElegida &&
        this.cliente && this.vendedor && this.documentoEncabezado.prefijo;
    }
  }

  private responseCodigoError(res: any, atributo: any) {
    if (res.atributo == 'moneda') {
      if (atributo == 'fecha') {
        this.monedaAux = Object.assign({}, this.moneda);
        this.fechaAnterior = res.valor;
      } else {
        this.monedaAux = Object.assign({}, this.moneda);
        this.moneda = this.valorAnteriorMoneda;
        this.valorTRM = null;
        this.valorTRMnumeric = null;
      }
      this.msjeCrearTRM = true;
    } else if (res.codigoError == '106') {
      this.navegarResoluciones = true;
    } else if (atributo == "terceroExterno" && res.codigoError == "344") {
      this.idClientePopUpDireccion = this.cliente.id;
    }
    if (res.atributo == 'numero') {
      this.documentoEncabezado.numero = this.ultimoNumeroValido;
    } else if (atributo == 'empresa') {
      this.documentoEncabezado.empresa = res.valor;
    } else if (atributo == 'formaPago') {
      this.valorAnteriorSelect;
      this.formaPago = this.valorAnteriorSelect;
    } else if (atributo == 'fecha') {
      this.documentoEncabezado.fecha = res.valor;
    } else if (atributo == 'prefijoExterno') {
      this.documentoEncabezado.prefijoExterno = this.prefijoExternoValido;
    } else if (atributo == 'numeroExterno') {
      this.documentoEncabezado.numeroExterno = this.numeroExternoValido;
    } else if (res.accion == 'asociarPrefijoDocumento') {
      this.documentoEncabezado.prefijo = res.valor;
    } else if (atributo == 'trm') {
      this.documentoEncabezado.trm = this.valorTRMnumeric;
    } else if (atributo == 'docAsociado') {
      this.documentoAsociado = this.documentoAsociadoCopia ? Object.assign(this.documentoAsociadoCopia) : null;
    } else if (atributo == 'terceroExterno') {
      this.cliente = this.clienteValido ? Object.assign(this.clienteValido) : null;
      this.documentoEncabezado.terceroExterno = Object.assign(this.clienteValido);
    }
    this.verificarEncabezado();
  }

  comprobarBorrador() {
    let estadoBorrador = false;
    this.listenerBorradorService.actualizarBorrador(this.screen, new Borrador(estadoBorrador, 'PIERDEN_CAMBIOS'));

    let permisos: any = sessionStorage.getItem('POS') !== '{}' ? new Map(JSON.parse(sessionStorage.getItem('POS'))) : [];
    if (permisos && (permisos.length > 0 || permisos.size > 0)) {
      permisos.forEach((value: any, key: any) => {
        if (value.senRequerido && value.codigoHtml.startsWith('POS/')) {
          let valorAtributo = this.documentoEncabezado[value.atributo];
          if (value.atributo === 'prefijo') {
            if (valorAtributo.nombre === '') {
              estadoBorrador = this.documentoEncabezado.concepto == 'ANULADA' ? estadoBorrador = false : estadoBorrador = true;
              this.listenerBorradorService.actualizarBorrador(this.screen, new Borrador(estadoBorrador, 'PIERDEN_CAMBIOS'));
            }
          } else {
            if (!valorAtributo || valorAtributo === '') {
              estadoBorrador = this.documentoEncabezado.concepto == 'ANULADA' ? estadoBorrador = false : estadoBorrador = true;
              this.listenerBorradorService.actualizarBorrador(this.screen, new Borrador(estadoBorrador, 'PIERDEN_CAMBIOS'));
            }
          }
        }
      });
    } else {
      estadoBorrador = !this.documentoEncabezado.empresa || !this.documentoEncabezado.terceroExterno || !this.documentoEncabezado.direccionTerceroExterno || !this.documentoEncabezado.terceroInterno || !this.documentoEncabezado.formaPago;
      estadoBorrador = this.documentoEncabezado.concepto == 'ANULADA' ? estadoBorrador = false : estadoBorrador;
      this.listenerBorradorService.actualizarBorrador(this.screen, new Borrador(estadoBorrador, 'PIERDEN_CAMBIOS'));
    }

    this.borrador = estadoBorrador;
    if (this.listenerBorradorService.validarEnvioBorrador(this.screen) && this.comenzoComoBorrador) {
      this.comenzoComoBorrador = false;
      this.numeroDisabled = false;
      let mensaje = this.listenerBorradorService.generarMensaje('DocumentoEncabezado', this.idDocumento, 'editarDocumentoEncabezado', this.borrador);
      const variable = this.serviceColas.agregarACola(mensaje);
      if (variable) {
        const sub = variable.pipe(take(1)).subscribe(async response => {
          if (response && response[0]) {
            if (response[0].codigoError) {
              this.borrador = true;
              this.comenzoComoBorrador = true;
              if (this.documentoEncabezado.numero) {
                this.documentoEncabezado.prefijo.nombre = 'Sin prefijo';
              } else {
                this.documentoEncabezado.prefijo = null;
              }
              if (response[0].codigoError.toString().includes('106')) {
                this.documentoEncabezado.formaPago = null;
                this.formaPago = null;
                this.navegarResoluciones = true;
              }
            } else {
              this.borrador = false;
              if (response[0].atributo == 'numero' && (response[0].codigoError == undefined || response[0].codigoError == null)) {
                let doc = await this.productosDBService.getDocumentoencabezado(this.documentoEncabezado.id);
                doc.creadoEnLinea = true;
                this.ultimoNumeroValido = response[0].valor;
                this.datosEncabezado.numero = response[0].valor;
                this.documentoEncabezado.numero = response[0].valor;
                doc.numero = response[0].valor;
                if (!this.documentoEncabezado.prefijo || !this.documentoEncabezado.prefijo.nombre) {
                  this.documentoEncabezado.prefijo.nombre = 'Sin prefijo';
                  if (!doc.prefijo || !doc.prefijo.nombre) {
                    doc.prefijo.nombre = 'Sin prefijo';
                  }
                }
                this.navegarResoluciones = false;
                if (this.sincronizando) this.documentoEncabezado.modificadoEnSync = true;
                this.productosDBService.setDocumentoencabezado(doc);
                this.posService.cambiosEnListado.next({ tipoCambio: 'nuevo', id: this.documentoEncabezado.encabezadoId });
              }
              this.datosEncabezado.creadoEnLinea = true;
              setTimeout(() => {
                this.cambioDatosEncabezado.emit(this.datosEncabezado);
              }, 500);
              this.verificarEncabezado();
            }
          } else {
            this.borrador = false;
            this.posService.cambiosEnListado.next({ tipoCambio: 'nuevo', id: this.documentoEncabezado.encabezadoId });
            this.datosEncabezado.creadoEnLinea = true;
            setTimeout(() => {
              this.cambioDatosEncabezado.emit(this.datosEncabezado);
            }, 500);
            this.verificarEncabezado();
          }
        });
        this.subscriptions.add(sub);
      }
    } else {
      this.numeroDisabled = true;
    }
  }

  cerrarEsc2(event) {
    this.verMasOpciones.emit(false);
    this.mostrarPopup2 = false;
    this.camposPersonalizadosIncompletos = true;
    this.mostrarPopupConfirmarEliminacion = false;
    this.mostrarPopUpDescuento = false;
    this.camposPersonalizadosIncompletos = true;
    this.cerrarPopUp();
    this.verificarEncabezado();
  }

  onNotifyDocVerificado(message: boolean): void {
    this.docVerificado = message;
  }

  cerrarPopUp() {
    this.mostrarImprimirFactura = false;
    this.dataInicializarImprimir = null;
  }

  getValidacionBotonMas(): any {
    let validacion = new ValidacionBotonMas();
    validacion.validacionPestanaContacto = this.getValidacionPestanaContacto()
    validacion.validacionPestanaClasificacion = this.getValidacionPestanaClasificacion();
    validacion.validacionPestanaOtros = this.getValidacionPestanaOtros();
    validacion.validacionElaboradoPor = this.getValidacionElaboradoPor();
    return validacion;
  }

  getValidacionElaboradoPor() {
    return new ValidacionElaboradoPor(true);
  }

  getValidacionPestanaOtros(): any {
    return new ValidacionPestanaOtros(true, true, true);
  }
  getValidacionPestanaClasificacion(): any {
    return new ValidacionPestanaClasificacion(true, true);
  }
  getValidacionPestanaContacto(): any {
    return new ValidacionPestanaContacto(true, true);
  }

  onNotifyClicked(): void {
    this.verMasOpciones.emit(false);
    this.camposPersonalizadosIncompletos = true;
  }

  abrirMasOpciones() {
    if (this.sonidoClick) {
      this.sonidoClick.pause();
      this.sonidoClick.currentTime = 0;
      this.sonidoClick.volume = 0.05;
      this.sonidoClick.play();
    }
    console.log('this.datosEncabezado:', this.datosEncabezado)
    console.log('this.documentoEncabezado:', this.documentoEncabezado)
    this.verMasOpciones.emit({
      cambios: this.cambios,
      validacionBotonMas: this.validacionBotonMas,
      docVerificado: this.docVerificado,
      docExportado: this.docExportado,
      atributosEncabezado: this.atributosEncabezado
    });
    this.subscriptions.add(
      this.posService.respuestaMasOpciones.subscribe((resp: any) => {
        this[resp.tipo]();
      })
    );
  }

  clickEnBoton(resp) {
    if (['cliente', 'vendedor'].includes(resp)) {
      this.crearTercero.emit(resp);
    } else if (resp == 'direccion') {
      this.crearDireccion.emit();
    }
  }
}
