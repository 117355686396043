<app-menu-contextual></app-menu-contextual>
<form class="form-inputs productos" (click)="cerrarMenuContextual()">
  <div *ngIf="!permitirAgregarProducto" class="agregandoProductoNuevo flex flexColumn">
    <i class="fas fa-spinner fa-spin"></i>
    <p>{{'puntosVenta.agregandoProducto' | translate}}</p>
  </div>
  <div id="contenedorFiltros" class="contGridFluid">
    <div class="colGrid1 barraBusqueda flex">
      <div class="form-group col-10 fas fa-search">
        <fieldset>
          <i class=" iconoBusqueda col-2 fas fa-barcode" [title]="'puntosVenta.busquedaCodigoBarras' | translate"></i>
          <wo-text-field-seg class="inputNumDoc" [label]="'puntosVenta.busqueda'" id="valorBusqueda" [maxlength]="50"
            [valorActual]="valorBusqueda" (activarLabelEmit)="getBuscarInventarios(0)" [cantidadEnteros]="20"
            (enter)="valorBusqueda = $event; buscarInventario(false, true)"
            (tab)="valorBusqueda = $event; buscarInventario(false, true)" [detectarEnter]="true" [detectarTab]="true"
            [pantalla]="'tercero'" (respuesta)="valorBusqueda = $event; buscarInventario(false)" [conBoton]="false"
            [iconBoton]="'fas fa-barcode'" [editarConBoton]="true" [debounce]="1000" [disabledLocalImportante]="!permitirAgregarProducto">
          </wo-text-field-seg>
          <i *ngIf="valorBusqueda?.length > 0" class="fas fa-times borrarValorBusqueda"
            (click)="valorBusqueda = null; buscarInventario(false)" (keydown.enter)="$event.preventDefault()"></i>
        </fieldset>
      </div>
    </div>
    <!-- <div class="colGrid1 grupos flex">
      <div class="form-group fas fa-layer-group" (click)="getGruposAcordeon($event)"
        (keydown.enter)="$event.preventDefault()">
        <acordeon-select-seg id="clasificaciones" [id]="'1'" [columnas]="['codigo','nombreGrupo']"
          [arbol]="gruposInventario" [label]="'puntosVenta.gruposInventario'" [tipo]="'multiselect'"
          [filtroLabel]="false" [seleccionados]="gruposSeleccionados" [atributo]="'nombreGrupo'"
          [atributoMostrar]="'nombreGrupo'" [minChars]="'1'" (respuesta)="buscarInventario(false)">
        </acordeon-select-seg>
      </div>
    </div> -->
    <div class="opcionesVisualizacion flex">
      <i class="fas fa-th" [ngClass]="{'visualizacionActive': !visualizacionList}"
        (click)="cambioVisualizacion('Items')" (keydown.enter)="$event.preventDefault()"
        [title]="'POS.puntosVenta.opciones.verItemsProductos' | translate"></i>
      <i class="fas fa-list" [ngClass]="{'visualizacionActive': visualizacionList}"
        (click)="cambioVisualizacion('Lista')" (keydown.enter)="$event.preventDefault()"
        [title]="'POS.puntosVenta.opciones.verListaProductos' | translate"></i>
    </div>
  </div>
  <div *ngIf="dataInventariosMasUsados?.length > 0" id="contenedorListadoProductos" class="contenedorProductos">
    <div *ngIf="!visualizacionList" id="listadoProductos" class="listado"
      [ngClass]="{'noClickProductos': !permitirAgregarProducto}">
      <button type="button" class="cardProducto" [ngClass]="{'noExistenciasCard': !producto.mostrar}"
        *ngFor="let producto of dataInventariosMasUsados" (click)="abrirPopUp($event,producto)"
        (keydown.enter)="$event.preventDefault()" (contextmenu)="onRightClick($event, producto)">
          
        <img
          [src]="producto.imagen ? producto.imagen : '/assets/images/pos/imgProducto.jpg'"
          alt=" " loading="lazy" onerror="this.onerror=null;this.src='/assets/images/pos/imgProducto.jpg'" />
        <div class="contenedorTexto">
          <p class="nombreProducto" [title]="producto.descripcion">{{producto.descripcion}}</p>
          <span class="precioProducto">{{(producto.listaPrecios && producto.listaPrecios.precioTotal ?
            (producto.listaPrecios.precioTotal |
            formatearNumero:'':null:'moneda') : ('puntosVenta.sinPrecio' | translate))}}</span>
        </div>
        <div class="flex contenedorIcoono" [ngClass]="{'productoFavorito': producto.senFavorito}">
          <i class="fas {{producto.senFavorito ? 'fa-star':'fa-cart-plus'}}"></i>
        </div>
      </button>
    </div>

    <div id="listadoProductosLista" *ngIf="visualizacionList">
      <div class="productosLista" [ngClass]="{'noClickProductos': !permitirAgregarProducto}">
        <div class="tituloFilas">
          <div></div>
          <p class="codigoTitulo">{{'puntosVenta.productosEnFila.codigo' | translate}} <i
              class="fas fa-angle-down icoOrdenar"
              [ngClass]="{'noActivo': ordenarProductos != 'codigo' , 'cambio': ordenarProductos == 'codigo' && codigoOrdenar == 'DESC'}"
              (click)="cambioOrdenamiento('codigo')"></i>
          </p>
          <p class="nombreTitulo">{{'puntosVenta.productosEnFila.nombre' | translate}} <i
              class="fas fa-angle-down icoOrdenar"
              [ngClass]="{'noActivo': ordenarProductos != 'descripcion', 'cambio': ordenarProductos == 'descripcion' && descripcionOrdenar == 'DESC'}"
              (click)="cambioOrdenamiento('descripcion')"></i>
          </p>
          <p class="precioTitulo">{{'puntosVenta.productosEnFila.precio' | translate}}</p>
          <p class="grupoTitulo">{{'puntosVenta.productosEnFila.grupo' | translate}}</p>
          <div></div>
        </div>
        <div class="filaProducto" [ngClass]="{'noExistencias': !producto.mostrar}"
          *ngFor="let producto of dataInventariosMasUsados, let i=index" (contextmenu)="onRightClick($event, producto)"
          (keydown.enter)="$event.preventDefault()" (click)="abrirPopUp($event, producto)">
          <ng-container>
            <div class="flex">
              <img
                [src]="producto.imagen ? producto.imagen : '/assets/images/pos/imgProducto.jpg'"
                alt=" " loading="lazy" onerror="this.onerror=null;this.src='/assets/images/pos/imgProducto.jpg'" />
            </div>
            <p class="codigo">{{producto.codigo}}</p>
            <p class="nombreEnLista">{{producto.descripcion}}</p>
            <p class="precioEnLista flex flexStart">{{(producto.listaPrecios && producto.listaPrecios.precioTotal ?
              (producto.listaPrecios.precioTotal |
              formatearNumero:'':null:'moneda') : ('puntosVenta.sinPrecio' | translate))}}</p>
            <p class="grupoEnLista">{{producto.grupo && producto.grupo.nombre ? producto.grupo.nombre : '-'}}</p>
          </ng-container>
          <div class="contenedorIcoono flex" [ngClass]="{'productoFavorito': producto.senFavorito}">
            <i class="fas {{producto.senFavorito ? 'fa-star':'fa-cart-plus'}}"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="noItems" *ngIf="(!dataInventariosMasUsados || dataInventariosMasUsados.length == 0) && !loading"
    class="contenedorProductos flex column">
    <i class="fab fa-grav"></i>
    <p>{{(valorBusqueda || gruposSeleccionados.length > 0 ? 'puntosVenta.noProductosBusqueda':'puntosVenta.noProductos')
      | translate}}</p>
    <button *ngIf="valorBusqueda || gruposSeleccionados.length > 0" class="flex"
      (click)="valorBusqueda = null; palabraBusqueda === ''; gruposSeleccionados.length = 0; getBuscarInventarios('')"
      (keydown.enter)="$event.preventDefault()">{{'puntosVenta.volverAtrasProductos'
      |
      translate}}</button>
  </div>
  <div id="loadingProductos" *ngIf="loading" class="contenedorProductos flex column">
    <i class="fas fa-spinner fa-spin"></i>
    <p>{{'puntosVenta.cargando' | translate}}</p>
  </div>
</form>

<div id="popUpMasInventarioId" class="popupMasInventario">
  <app-mas-inventario [temaDocumento]="'themePOS'"></app-mas-inventario>
</div>