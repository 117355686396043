<div class="themeCont {{POS ? 'themePOS':''}}">

  <div class="titlePrincipal flex column flexStart2">
    <h1>
      <span class="contTit">{{'PANEL.balanzasPredeterminadas' | translate}}</span>
    </h1>
  </div>

  <div class="contFilter" [class.posFilter]="POS" style="margin-bottom: 3px;">
    <div class="infoFilter" >
      <button
      type="button"
      (click)="filtro()"
      class="iconFilter fas fa-filter buttonFilter"
      [ngClass]="{ 'cloudFilter': !POS, 'posFilter': POS }"
      aria-label="Filtrar">
    </button>    
      <ng-container *ngIf="filtrosMostrar?.length > 0">
            <p *ngFor="let filtro of filtrosMostrar" title="{{filtro.nombreColumna}}-{{'FILTRO.'+filtro.tipoFiltro | translate}}-{{filtro.valor}}">
                <span class="columna">{{filtro.nombreColumna}} {{'FILTRO.'+filtro.tipoFiltro | translate}}</span><i>:</i>
                <span *ngIf="filtro.tipoDato!=tiposDatos[0] && filtro.tipoDato!=tiposDatos[1]">{{filtro.valor}}</span> <span
                *ngIf="filtro.tipoDato==tiposDatos[1]">{{'FILTRO.'+filtro.valor | translate }}</span>
                <span class="listFiltro" *ngIf="filtro.tipoDato!=tiposDatos[0]">
                    <span *ngFor="let v of filtro.valores">
                      {{v}}
                    </span>
                </span>
                <span *ngIf="filtro.valor2">-{{filtro.valor2}}</span>
                <button (click)="borrarFiltro(filtro)" title="{{'filtroGeneral.eliminarFiltro' | translate}}">X</button>
            </p>
            <button class="closeFilter fas fa-times" *ngIf="filtrosMostrar?.length > 0" (click)="borrarTodosFiltros()" title="{{ 'filtroGeneral.eliminarTodos' | translate }}" ></button>
      </ng-container>
    </div>
  </div>
  
  <div class="contListado " id="especial"
  [ngStyle]="{'padding-top' : filtrosMostrar.length > 0 ? '0' : '10px'}"
    >
    <wo-tabla id="configuracionBalanza" 
    [paginacion]="paginacion"
    [tablaEnum]="tablaEnum"
    [mostrarPaginador]="true"
    [datos]="datos"
    [columnas]="columnas"
    [tituloTabla]="''"
    [opciones]="opcionesTabla"
    (afterChange)="afterChange($event)">
  </wo-tabla>
  </div>
</div>

<app-filtro-tablas [temaDocumento]="''" (respuestaFiltro)="respuestaFiltro($event)"></app-filtro-tablas>

<boton-flotante [opciones]="opcion" (respuestaBotonF)="respuestaBoton($event)"></boton-flotante>