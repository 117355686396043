import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import {
  Columna,
  TipoDato,
  TipoInput,
  FiltroWo,
  TipoFiltro,
  Operador,
  DocumentoTipoEnum,
  SeveridadEnum,
  TipoTercero,
  PlataformaEnum,
  OpcionTabla, PaginacionWo
} from '@wo/modelo';
import { IntegracionPosService } from '@wo/servicio/lib/pos/integracion-pos.service';
import { ColaService, ConfiguracionGeneralService, ScrollInfinitoService, ToastService, TOAST_STATE } from '@wo/servicios';
import { Subscription } from 'rxjs';
import { ErrorHandlerComponent } from '../error-handler/error-handler.component';
import { ListaPreciosOtraMonedaComponent } from '../lista-precios/lista-precios-otra-moneda/lista-precios-otra-moneda.component';
import { SelectPagComponent } from '../select-pag/select-pag.component';
import { PopUpService } from '../servicios/popUp/pop-up.service';
import { TranslateUtilService } from '../utils/i18n/translate-util.service';
import { DescuentomaximoVendedorComponent } from './descuentomaximo-vendedor/descuentomaximo-vendedor.component';
import { LoaderService } from "@wo/frontend/servicios/loader/loader.service";

interface Plantilla {
  codigo: string;
  id: number;
  valor: string;
  nombre: string;
}

interface Token {
  id: number;
  email: string;
  jwt: string;
  fechaVencimiento: string;
  fechaRegistro?: string;
  sesTokenActivo: any;
}
@Component({
  selector: 'app-configuracion-general',
  templateUrl: './configuracion-general.component.html',
  styleUrls: ['./configuracion-general.component.css']
})

export class ConfiguracionGeneralComponent implements OnInit, OnDestroy {

  @ViewChild(DescuentomaximoVendedorComponent, { static: false })
  popUpDescuentoMaximo: DescuentomaximoVendedorComponent;

  @ViewChild(ListaPreciosOtraMonedaComponent, { static: false })
  private popup: ListaPreciosOtraMonedaComponent;

  private subscriptions = new Subscription();
  pestana: any = null;
  mostrarTabla: boolean = false;
  datosTabla: any[] = [];
  noEntrar: boolean = true;
  pestanaImpresiones = {
    active: 'active',
    codigo: 8000,
    id: 4,
    nombre: 'Impresiones'
  };
  isFinalClick: boolean = false;
  dataCuentaServicio = {
    valoresDisponibles: [],
    valorSelected: {
      id: -1,
    }
  }
  documentosTicket = [
    DocumentoTipoEnum.FACTURA_VENTA,
    DocumentoTipoEnum.POS,
    DocumentoTipoEnum.COTIZACION,
    DocumentoTipoEnum.PEDIDO,
    DocumentoTipoEnum.COMPROBANTE_EGRESO,
    DocumentoTipoEnum.NOTA_DEBITO_VENTA,
    DocumentoTipoEnum.NOTA_CREDITO_VENTA,
    DocumentoTipoEnum.RECIBO_CAJA,
    DocumentoTipoEnum.SALIDA_ALMACEN,
    DocumentoTipoEnum.ENTRADA_ALMACEN,
    DocumentoTipoEnum.REMISION,
    DocumentoTipoEnum.DEVOLUCION_REMISION
  ];

  POS: boolean;
  listaAtajos = [
    { id: '', codigo: 11006, icono: 'fa-print', titulo: 'atajosTeclado.imprimir', atajo: '' },
    { id: '', codigo: 11009, icono: 'fa-receipt', titulo: 'atajosTeclado.imprimirTicket', atajo: '' },
    { id: '', codigo: 11007, icono: 'fa-hand-holding-usd', titulo: 'atajosTeclado.pagoRapido', atajo: '' },
    { id: '', codigo: 11010, icono: 'fa-calculator', titulo: 'atajosTeclado.contabilizar', atajo: '' },
    { id: '', codigo: 11008, icono: 'fa-file-invoice', titulo: 'atajosTeclado.crearFactura', atajo: '' }
  ];

  auxPlantillaFeAnterior: any;
  auxPlantillaAnterior: any;
  filtroARL: Array<FiltroWo> = [new FiltroWo('senActivo', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('senDisponible', 'true', null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'terceroTipos', Operador.AND), new FiltroWo('codigo', '', null, TipoFiltro.IGUAL, TipoDato.LISTA, null, [TipoTercero.ARL], 'terceroTipos', Operador.AND)];

  constructor(
    private scrollInfinitoService: ScrollInfinitoService,
    private configuracionGeneralService: ConfiguracionGeneralService,
    private serviceCola: ColaService,
    private errorHandlerComponent: ErrorHandlerComponent,
    private translateUtilService: TranslateUtilService,
    private popUpService: PopUpService,
    public _LoaderService: LoaderService,
    private integracionPOS: IntegracionPosService,
    private toast: ToastService,
    @Inject('plataforma') plataforma: PlataformaEnum
  ) {
    this.POS = plataforma == PlataformaEnum.POS;
  }

  PREFERENCIA_DECIMALES = {
    "31011": 0,
    "31012": 2,
    "31013": 3
  }

  filtroPantillasDocumento = new FiltroWo('esDocumento', "true", null, TipoFiltro.IGUAL, TipoDato.BOOLEAN, null, null, 'Reporte', Operador.AND);
  filtroPantillasTicket = new FiltroWo('codigo', "ET_", null, TipoFiltro.CONTIENE, TipoDato.STRING, null, null, 'Reporte', Operador.AND);
  filtroPantillasTicketPos = new FiltroWo('clasificacion.nombre', "POS", null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'Reporte', Operador.AND);
  filtrosTicket = [this.filtroPantillasTicketPos]
  filtrosPlantillas = [this.filtroPantillasDocumento]
  filtrosCuentaAPI: Array<FiltroWo> = [new FiltroWo('email', '', null, TipoFiltro.CONTIENE, TipoDato.STRING, null, null, null, Operador.AND), new FiltroWo('canal', 'Cuenta API', null, TipoFiltro.CONTIENE, TipoDato.STRING, null, null, null, Operador.AND)];

  paginacionListadoToken: any;
  cuentaServicioSelected: any;
  mostrarErrores: boolean = false;
  activarBtnCrearToken = true;


  paginacion: any = {
    pagina: 0,
    registrosPorPagina: 10,
    orden: 'ASC',
    columnaOrdenar: 'descripcion'
  };

  documentosAutoCompleto = true;
  mostrarErroresDocAuto: boolean = false;

  nivelBalance: any = [0, 0, 0, 0, 0, 0, 0];
  nivelPyG: any = [0, 0, 0, 0, 0, 0, 0];


  public columnas: Columna[] = [
    {
      header: this.translateUtilService.getTranslateText(
        'configuracionGeneral.documento'
      ),
      atributo: 'descripcion',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      requerido: false,
      maxLen: 15,
      soloLectura: true,
      hidden: false,
      editable: false,
      upperCase: true,
      estilo: 'colWidth160'
    },

    {
      header: this.translateUtilService.getTranslateText(
        'configuracionGeneral.plantilla'
      ),
      atributo: 'plantilla',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.SELECT,
      columnas: ['nombre'],
      columnasRequeridas: ['nombre', 'codigo'],
      entidad: 'Reporte',
      requerido: false,
      pag: true,
      filtros: this.filtrosPlantillas,
      registrosPorPagina: 10,
      soloLectura: false,
      hidden: false,
      editable: true,
      maxLen: 50,
      ordenar: false,
      estilo: 'colWidth200'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'configuracionGeneral.plantillaFacturaElectronica'
      ),
      atributo: 'plantillaFE',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.SELECT,
      columnas: ['nombre'],
      columnasRequeridas: ['nombre', 'codigo'],
      entidad: 'Reporte',
      filtros: this.filtrosPlantillas,
      requerido: false,
      pag: true,
      registrosPorPagina: 10,
      soloLectura: false,
      hidden: false,
      editable: true,
      maxLen: 50,
      ordenar: false,
      estilo: 'colWidth200'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'configuracionGeneral.imprimirTicket'
      ),
      atributo: 'plantillaTK',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.SELECT,
      columnas: ['nombre'],
      columnasRequeridas: ['nombre', 'codigo'],
      entidad: 'Reporte',
      requerido: false,
      pag: true,
      filtros: this.filtrosTicket,
      registrosPorPagina: 10,
      soloLectura: false,
      hidden: false,
      editable: true,
      maxLen: 50,
      ordenar: false,
      estilo: 'colWidth200'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'configuracionGeneral.mostrar'
      ),
      atributo: 'senMostrar',
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      requerido: false,
      soloLectura: false,
      hidden: false,
      editable: true,
      ordenar: false
    },
    {
      header: this.translateUtilService.getTranslateText(
        'configuracionGeneral.imprimirDecimales'
      ),
      atributo: 'imprimirDecimales',
      tipoDato: TipoDato.BOOLEAN,
      tipoInput: TipoInput.CHECK,
      requerido: false,
      soloLectura: false,
      hidden: false,
      editable: true,
      ordenar: false
    }
  ];

  public columnasToken: Columna[] = [

    {
      header: this.translateUtilService.getTranslateText(
        'Usuario'
      ),
      atributo: 'email',
      ordenar: true,
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      requerido: false,
      soloLectura: true,
      hidden: false,
      editable: false,
      upperCase: false,
      estilo: 'colWidth200'
    },

    {
      header: this.translateUtilService.getTranslateText(
        'Token'
      ),
      atributo: 'jwt',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      requerido: false,
      permiteClickDisabled: true,
      soloLectura: true,
      hidden: false,
      editable: false,
      ordenar: false,
      estilo: 'colWidth400'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'Fecha de vencimiento'
      ),
      atributo: 'fechaVencimiento',
      tipoDato: TipoDato.FECHA,
      tipoInput: TipoInput.DATE,
      requerido: false,
      soloLectura: false,
      hidden: false,
      editable: true,
      ordenar: false,
      estilo: 'colWidth25'
    },
    {
      header: this.translateUtilService.getTranslateText(
        'Estado'
      ),
      atributo: 'sesTokenActivo',
      tipoDato: TipoDato.STRING,
      tipoInput: TipoInput.TEXT,
      requerido: false,
      soloLectura: true,
      hidden: false,
      editable: false,
      ordenar: false,
      estilo: 'colWidth25'
    },
  ];

  contentTokens: Token[] = [];

  opcionesTabla = {
    editable: true,
    paginado: true,
    menu: [],
    crear: false
  };

  opcionesTablaToken = {
    crear: false,
    editable: true,
    paginado: true,
    menu: [OpcionTabla.REVOCAR],
    ordenar: true
  };

  tab: String;
  public pestanas: Array<any> = [];
  mostrarContenidoPestana: boolean = true;
  contenido = [];
  anteriorPlantilla = null;
  anteriorPlantillaFE = null;
  anteriorPlantillaTK = null
  codigoClasificacionActual: any = -1;

  ngOnInit() {
    let sub = this.configuracionGeneralService.get().subscribe(
      response => {
        response.forEach(element => {
          let datos = {
            id: element.id,
            nombre: element.nombre,
            active: '',
            codigo: element.codigo,
            senDisponible: element.senDisponible
          };
          if (this.POS) {
            if ([8000].includes(element.codigo)) this.pestanas.push(datos);
            if ([10000].includes(element.codigo)) this.pestanas.unshift(datos);
          } else if (![10000, 12000].includes(element.codigo)) {
            this.pestanas.push(datos);
          }
        });

        if (this.POS) {
          const atajosTeclado = response.find(element => [12000].includes(element.codigo));
          if (atajosTeclado) this.pestanas.push(atajosTeclado);
          const balanza = response.find(element => [16000].includes(element.codigo));
          if (balanza) this.pestanas.push(balanza);
        }
        let pestanaCuentaAPI = Boolean(this.pestanas.find(element => element.codigo == 13000));
        let pestanaInicial = this.buscarPestana(this.POS ? 10000 : pestanaCuentaAPI ? 13000 : 2);
        if (!this.POS) pestanaInicial = !this.pestanas[pestanaInicial].senDisponible ? this.buscarPestana(3000) : pestanaCuentaAPI ? this.buscarPestana(13000) : this.buscarPestana(2);
        this.cambiarPestana(this.pestanas[pestanaInicial]);
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);

    if (this.POS) {
      const columnaTicket = this.columnas.find(col => col.atributo === 'plantillaTK');
      columnaTicket.header = this.translateUtilService.getTranslateText(
        'configuracionGeneral.imprimirTicketPOS'
      );
    }
  }


  afterChange(event) {
    let envio: boolean;
    if (event.edita) {
      if (event.columna == 'plantillaTK') {
        this.anteriorPlantillaTK = event.valorAnterior;
        envio = true;
      }
      if (event.columna == 'plantillaFE' || event.columna == 'plantillaFE') {
        this.auxPlantillaFeAnterior = event.valorAnterior;
      }
      if (event.columna == 'plantillaFE') {
        this.auxPlantillaAnterior = event.valorAnterior;
      }

      if (this.pestana.codigo !== 13000) {
        this.cambioCheckImpresion(this.contenido[event.fila].id, event.valorNuevo, event.columna, event.fila);
      } else {
        if (event.columna == 'fechaVencimiento') {
          this.cambioFechaCuentaAPI(this.contentTokens[event.fila], event.valorNuevo);
        }
      }
    }

    if (event.pag) {
      this.paginacion = {
        pagina: event.pagActual,
        registrosPorPagina: event.registrosPorPagina,
        orden: event.orden,
        columnaOrdenar: event.columna
      };

      if (this.pestana.codigo == 13000) {
        this._LoaderService.colocarMascara("cambioPag", { tabla: false }, "loadingIndex", "paneTabs");
        this.cargarTablaAPI(event.pagActual, event.registrosPorPagina, event.columna, event.orden, event.registroInicial);
      }

      if (this.pestana.codigo !== 13000) {
        this.llenarTabla(event.registroInicial);
      }
    }

    if (event.revocarToken) {
      this.revocarToken(this.contentTokens[event.fila]);
    }

    if (event.ordenar === true) {
      if (this.pestana.codigo == 13000) {

        this.contentTokens = [];
        this._LoaderService.colocarMascara("cambioPag", { tabla: false }, "loadingIndex", "paneTabs");
        this.cargarTablaAPI(event.pagActual, this.paginacionListadoToken.registrosPorPagina, event.columna, event.orden, event.registroInicial);
      }

      if (this.pestana.codigo !== 13000) {
        this.paginacion.pagActual = event.pagActual;
        this.paginacion.registrosPorPagina = event.registrosPorPagina;
        this.paginacion.columna = event.columna;
        this.paginacion.orden = event.orden;
        this.contenido = [];
        this.llenarTabla();
      }
    }
  }

  cambiarPestana(pestana) {
    this.pestana = pestana
    this.serviceCola.enviar();
    (!pestana.senDisponible) && this.popUpService.open({ codigoError: 'noDisponible', severidad: SeveridadEnum.WARNING });
    if (this.codigoClasificacionActual != pestana.codigo) {
      if (this.codigoClasificacionActual == 9000 && !this.documentosAutoCompleto) {
        this.mostrarErroresDocAuto = true;
        this.popUpService.open({ codigoError: 'debeCompletarRequeridosDocumentosAutomaticos', severidad: SeveridadEnum.WARNING });
      } else {
        this.mostrarErroresDocAuto = false;
        this.codigoClasificacionActual = pestana.codigo;
        pestana.active = 'active';
        this.quitarActive(pestana.id);
        this.mostrarContenidoPestana = true;
        this.contenido = [];
        if (this.codigoClasificacionActual != this.pestanaImpresiones.codigo) {
          this.mostrarTabla = false;
          const idBuscar = pestana.id;
          let sub = this.configuracionGeneralService.getHijos(idBuscar).subscribe(response => {
            if (response[0].codigo == 11001) {
              const contabilizarDocPagoRapido = response[0].configuracionGenerales.find(e => e.codigo == 11046);
              const contabilizarDocMediosPago = response[0].configuracionGenerales.find(e => e.codigo == 11047);
              response[0].configuracionGenerales = response[0].configuracionGenerales.filter(e => e.codigo != 11046 && e.codigo != 11047);
              response[0].configuracionGenerales.unshift(contabilizarDocMediosPago);
              response[0].configuracionGenerales.unshift(contabilizarDocPagoRapido);
            }

            this.contenido = response;
            this.contenido.forEach(elementMayor => {
              const element7003 = elementMayor.configuracionGenerales.find(({ codigo }) => codigo == 7003);
              if (element7003) {
                element7003.valoresDisponibles.forEach(e => {
                  if (e.id == 17) {
                    e.tooltip = this.translateUtilService.getTranslateText(
                      'CONFIGURACION_GENERAL.asignacionSalarial'
                    );
                  } else if (e.id == 18) {
                    e.tooltip = this.translateUtilService.getTranslateText(
                      'CONFIGURACION_GENERAL.devengadosConstitutivosSalario');
                  } else if (e.id == 19) {
                    e.tooltip = this.translateUtilService.getTranslateText(
                      'CONFIGURACION_GENERAL.pagarSiempreAuxilio');
                  } if (e.id == 20) {
                    e.tooltip = this.translateUtilService.getTranslateText(
                      'CONFIGURACION_GENERAL.todosLosDevengados');
                  }
                });
              }
              const element7004 = elementMayor.configuracionGenerales.find(({ codigo }) => codigo == 7004);
              if (element7004) element7004.valor = { id: -1, nombreCompleto: element7004.valor };
              const configuraciones = elementMayor.configuracionGenerales.filter(({ codigo }) => ![7004].includes(codigo));
              configuraciones.forEach(element => {
                if (element.tipo == 'CHECK' || element.tipo == 'CHECKBUTTON') {
                  if (element.valor == 'false') {
                    element.valor = false;
                  } else {
                    element.valor = true;
                  }
                } else if (element.tipo == 'SELECT' || element.tipo == 'SELECTBUTTON') {
                  if (element.valorCombo != null) {
                    let valorInicial = element.valoresDisponibles.indexOf(
                      element.valoresDisponibles.find(function (buscado) {
                        let valorString = '' + buscado.codigo;
                        return valorString === element.valorCombo;
                      })
                    );
                    element.valorCombo =
                      element.valoresDisponibles[valorInicial];
                    element.valor = element.valoresDisponibles[valorInicial];
                  }
                } else if (element.tipo == 'CHECKSELECT') {
                  if (element.valorCombo != null) {
                    let valorInicial = element.valoresDisponibles.indexOf(
                      element.valoresDisponibles.find(function (buscado) {
                        let valorString = '' + buscado.codigo;
                        return valorString === element.valorCombo;
                      })
                    );
                    element.valorCombo =
                      element.valoresDisponibles[valorInicial];
                    element.valor = element.valoresDisponibles[valorInicial];
                  }
                  if (element.valor == 'false') {
                    element.valor = false;
                  } else {
                    element.valor = true;
                  }
                }
              });

              if (elementMayor.codigo == 11001) {
                elementMayor.configuracionGenerales.forEach((e: any) => {
                  if ([11006, 11007, 11008, 11009, 11010].includes(e.codigo)) {
                    const atajo = this.listaAtajos.find(atajoInicio => atajoInicio.codigo == e.codigo);
                    atajo.atajo = e.valorCombo ? e.valorCombo.valor : e.valor ? e.valor.valor : '';
                    setTimeout(() => this.integracionPOS.atajosTeclado.next(atajo), 500);
                  }
                });
              }

            });
            console.log('this.contenido:', this.contenido)
            console.log('this.columnas:', this.columnas)
            this.tab = pestana.nombre;
            this.noEntrar = true;
          },
            error => {
              this.errorHandlerComponent.handler(error);
            }
          );
          this.subscriptions.add(sub);

        } else {
          pestana.senDisponible && (this.mostrarTabla = true);
        }

        if (pestana.codigo == 3000) {
          this.configuracionGeneralService.getCuentaContableNivel().subscribe(
            response => {
              response.forEach(element => {
                if (element.cuentaContableEstadoFinanciero.nombre === "Balance General") {
                  this.nivelBalance[element.nivel - 1] = element;
                }
                if (element.cuentaContableEstadoFinanciero.nombre === "Ganancias Y Perdidas") {
                  this.nivelPyG[element.nivel - 1] = element;
                }
              });
            });
        }
      }
    } else {
      if (this.codigoClasificacionActual != -1) {
        pestana.active = 'active';
      }
    }
  }

  cambioFechaCuentaAPI(token: Token, fechaNueva: string) {

    let mensaje = {
      id: token.id,
      accion: 'editarCuentaAPI',
      clase: 'Usuario',
      atributo: 'fecha_vencimiento',
      valor: fechaNueva,
      tipoDato: TipoDato.FECHA,
      prioridad: true,
      permisoGrueso: this.POS ? 'ConfiguracionPos' : 'configuracionGeneral'
    };

    this.enviarMensajeAPI(mensaje, false);


  }

  setFinalClick(e) {
    this.isFinalClick = e
    if (!e) {
      this.contentTokens = [];
    }
    this._LoaderService.colocarMascara("cambioPag", { tabla: false }, "loadingIndex", "paneTabs");
    this.contentTokens = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.contentTokens, [], this.isFinalClick);
    this.contentTokens = this.contentTokens.map(x => Object.assign({}, x));
  }

  cargarTablaAPI(pagina, registrosPorPagina, columna, orden, registroInicial?) {

    const paginacion = new PaginacionWo(columna, pagina, registrosPorPagina, orden);

    if (registroInicial !== undefined) {
      paginacion.registroInicial = registroInicial;
    }
    console.log(paginacion);
    if (!this.isFinalClick || (this.isFinalClick && !(paginacion.registroInicial > this.paginacion.totalElementos - paginacion.registrosPorPagina))) {
      let sub = this.configuracionGeneralService.listaToken(paginacion).subscribe(response => {

        const { content } = response;
        const tokensTabla: Token[] = [];
        content.forEach((cuentaApi) => {
          const { usuarioTokens } = cuentaApi;

          usuarioTokens.forEach((token) => {

            delete token.fechaRegistro;
            const contenidoToken = {
              ...token,
              email: cuentaApi.email,
              sesTokenActivo: token.sesTokenActivo ? "Activo" : "Inactivo",
              ocultarOpciones: !token.sesTokenActivo
            }

            tokensTabla.push(contenidoToken);
          })

        });

        this.paginacionListadoToken = {
          totalPaginas: response.totalPages,
          totalElementos: response.totalElements > (response as any).size ? response.totalElements : tokensTabla.length,
          pagina: paginacion.pagina,
          registrosPorPagina: paginacion.registrosPorPagina,
          numeroElementos: response.numberOfElements,
          registroInicial: registroInicial,
          orden: orden,
          columna: columna,
          filtros: []
        };

        this.contentTokens = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacionListadoToken, this.contentTokens, tokensTabla, this.isFinalClick);
        this.contentTokens = this.contentTokens.map(x => Object.assign({}, x));
        this._LoaderService.quitarMascara("cambioPag", "tabla", "loadingIndex", "paneTabs");

        this.subscriptions.add(sub);

      },
        error => {
          this.errorHandlerComponent.handler(error);
        });
    }


  }

  cambioCuentaServicio(e) {
    if (e == undefined || e == null || e.id == -1) {
      this.activarBtnCrearToken = true;
    } else {
      this.activarBtnCrearToken = false;
    }
  }

  generarToken() {
    const { id: idCuentaApi } = this.cuentaServicioSelected;
    let mensaje = {
      id: idCuentaApi,
      accion: 'editarCuentaAPI',
      clase: 'Usuario',
      atributo: 'nuevo_token',
      valor: '',
      tipoDato: TipoDato.NUMERIC,
      prioridad: true,
      permisoGrueso: 'configuracionGeneral'
    };

    this.enviarMensajeAPI(mensaje, true);


  }


  revocarToken(token: Token) {

    let mensaje = {
      id: token.id,
      accion: 'editarCuentaAPI',
      clase: 'Usuario',
      atributo: 'estado',
      valor: false,
      tipoDato: TipoDato.BOOLEAN,
      prioridad: true,
      permisoGrueso: this.POS ? 'ConfiguracionPos' : 'configuracionGeneral'
    };

    this.popUpService.open({ codigoError: 'confirmarRevocarToken', severidad: SeveridadEnum.QUESTION, conBoton: true });
    this.esperarRespuesta(mensaje);
  }
  esperarRespuesta(mensaje) {
    const res = this.popUpService.respuesta.subscribe((respuesta) => {
      res.unsubscribe();
      if (respuesta.tipo == 'respuestaPopup') this.respuestaPopup(respuesta.event, mensaje);
    })
  }

  respuestaPopup(e: any, mensaje) {
    if (e) {
      this.enviarMensajeAPI(mensaje, true);
    }
  }

  cambioSelect(event) {
    let fila = event.fila;
    if (event.atributo == 'plantillaTK' && this.documentosTicket.some(t => t === this.contenido[fila].documento)) {
      this.filtrosTicket = [this.filtroPantillasTicket];
      this.filtrosTicket.push(new FiltroWo('clasificacion.nombre', this.contenido[fila].documento, null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'Reporte', Operador.AND));
      this.columnas[3].filtros = Object.assign(this.filtrosTicket);
    }
    if (event.atributo == 'plantilla' || event.atributo == 'plantillaFE') {
      this.filtrosPlantillas = [this.filtroPantillasDocumento];
      if (this.contenido[fila].documento == 'SI') {
        this.filtrosPlantillas.push(new FiltroWo('clasificacion.nombre', 'SI_INV', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'Reporte', Operador.AND));
        this.filtrosPlantillas.push(new FiltroWo('clasificacion.nombre', 'SI_AF', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'Reporte', Operador.OR));
        this.filtrosPlantillas.push(new FiltroWo('clasificacion.nombre', 'SI_DIF', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'Reporte', Operador.OR));
        this.filtrosPlantillas.push(new FiltroWo('clasificacion.nombre', 'SI_CON', null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'Reporte', Operador.OR));
      } else {
        this.filtrosPlantillas.push(new FiltroWo('clasificacion.nombre', this.contenido[fila].documento, null, TipoFiltro.IGUAL, TipoDato.STRING, null, null, 'Reporte', Operador.AND));
      }
      this.columnas[1].filtros = Object.assign(this.filtrosPlantillas);
      this.columnas[2].filtros = Object.assign(this.filtrosPlantillas);
    }
  }

  ubicarElemento() {
    let tablaHTML = document.getElementById('tablaUbicarID');
    if (tablaHTML) {
      this.pestanas.forEach((element, i) => {
        if (element.codigo == this.pestanaImpresiones.codigo) {
          let ubicacionTabla = document.getElementsByClassName('tablaInsertHere')[i];
          ubicacionTabla.appendChild(tablaHTML);
          this.noEntrar = false;
        }
      });
    }
  }

  ngDoCheck() {
    if (this.noEntrar) {
      this.ubicarElemento();
    }
  }

  quitarActive(id: any) {
    this.pestanas.forEach(element => {
      if (element.id != id) {
        element.active = '';
      }
    });
  }

  buscarPestana(codigo: any) {
    let pestanaInicial = this.pestanas.indexOf(
      this.pestanas.find(function (element) {
        return element.codigo === codigo;
      })
    );
    return pestanaInicial!=-1?pestanaInicial:0;
  }

  abrirPopUpMaximoVendedor() {
    this.popUpDescuentoMaximo.abrirPopUp();
  }

  /**
   * Envia los cambios detectados en la pantalla del usuario para ser enviados a la cola de mensajes
   * @param atributo
   * @param valor
   */
  cambio(atributo: string, valor: any, tipo: any, codigo?: number, optionSel?) {
    if (valor == '51011' || valor == '51013') {
      this.popUpService.open({
        codigoError: 'cambioValorPreciosYDescuentos',
        severidad: SeveridadEnum.WARNING
      });
    }

    let laAccion;
    let valorAnterior = true;
    let laPrioridad = true;
    if (valor) valorAnterior = false;
    let valorE = 'valor';
    if (tipo === 'SELECT') {
      valorE = 'valorCombo';
      laAccion = 'asociarConfGeneralValor';
      laPrioridad = true;
    }
    if (tipo === 'CHECK') {
      laAccion = 'editarConfiguracionGeneral';
      let valorAtributo = ['7', '8', '11', '12', '13', '14', '15', '16'];
      let atrib;
      valorAtributo.forEach(e => (atributo == e) ? (atrib = e) : undefined);
      if (atributo.toString() === atrib) {
        laPrioridad = false;
      }
    }
    if (tipo === 'SELECTCHECK' || tipo === 'SELECTTABLE') {
      valorE = 'valorCombo';
      laAccion = 'asociarConfGeneralValor';
    }

    if (codigo >= 1103 && codigo <= 1107) {
      this.cambioCheckDV(valor, codigo);
    }

    let aux = typeof valor;
    let tipoDato = TipoDato[TipoDato[aux.toUpperCase()]];
    let mensaje = {
      clase: 'ConfiguracionGeneral',
      id: atributo,
      accion: laAccion,
      atributo: valorE,
      valor: valor,
      prioridad: laPrioridad,
      tipoDato: tipoDato,
      permisoGrueso: this.POS ? 'ConfiguracionPos' : 'configuracionGeneral'
    };
    this.enviarCambio(mensaje, valor, atributo, valorAnterior, optionSel, codigo);
  }

  cambioTercero(id, valor, accion) {
    let mensaje = {
      clase: 'ConfiguracionGeneral',
      id,
      accion,
      atributo: 'tercero',
      valor: valor.id,
      prioridad: false,
      tipoDato: TipoDato[TipoDato.NUMERIC],
      permisoGrueso: this.POS ? 'ConfiguracionPos' : 'configuracionGeneral'
    };
    this.enviarCambio(mensaje);
  }

  enviarCambio(mensaje, valor?, atributo?, valorAnterior?, optionSel?, codOptionSel?) {

    let observable = this.serviceCola.agregarACola(mensaje);
    if (observable) {
      let sub = observable.subscribe(
        response => {
          if (response.length > 0 && response[0].codigoError) {
            if (response[0].atributo === "valorCombo") {
              this.contenido[0].configuracionGenerales[0].valoresDisponibles.forEach(element => {
                if (response[0].valor === element.codigo) {
                  this.contenido[0].configuracionGenerales[0].valorCombo = element;
                }
              });
            }

            this.volverValorAnterior(response[0].accion, atributo, valorAnterior);
            this.verPopup(response[0].codigoError, response[0].severidad);
            /*} else {
              if (valor == '31011' || valor == '31012' || valor == '31013') {

                sessionStorage.setItem('PREFERENCIADECIMALES', JSON.stringify(this.PREFERENCIA_DECIMALES[valor]));
              }*/
          } else {

            if (valor == '31011' || valor == '31012' || valor == '31013') {
              sessionStorage.setItem('PREFERENCIADECIMALES', JSON.stringify(this.PREFERENCIA_DECIMALES[valor]));
            } else if (codOptionSel == 11046) {
              sessionStorage.setItem('pagoRapidoContabiliza', valor ? 'true' : 'false');
            } else if (codOptionSel == 11047) {
              sessionStorage.setItem('pagoCompletoContabiliza', valor ? 'true' : 'false');
            } else {
              if (['11015', '11016'].includes(valor)) {
                sessionStorage.setItem('visualizacionListadoFacturas', valor === '11016' ? 'Tabla' : 'Lista');
              } else if (['11017', '11018'].includes(valor)) {
                sessionStorage.setItem('visualizacionProductos', valor === '11018' ? 'Items' : 'Lista');
              } else if (['11019', '11020'].includes(valor)) {
                sessionStorage.setItem('visualizacionResumen', valor === '11020' ? 'Tabla' : 'Lista');
              } else if (['11006', '11007', '11008', '11009', '11010'].includes(optionSel.codigo.toString())) {
                const atajo = this.listaAtajos.find(atajoInicio => atajoInicio.codigo == optionSel.codigo);
                atajo.atajo = optionSel.valor ? optionSel.valor.valor : '';
                setTimeout(() => this.integracionPOS.atajosTeclado.next(atajo), 500);
              }
            }
          }

        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  cambioCheckDV(valor: boolean, codigo: number) {
    let laAccion = 'modificarDigitoConfiguracionGeneral';
    let laPrioridad = true;
    let valorE = 'sen_manejadv';
    let tipoDato = typeof (valor);
    let atributo: number;

    switch (codigo) {
      case 1103: atributo = 3; break;
      case 1104: atributo = 5; break;
      case 1105: atributo = 8; break;
      case 1106: atributo = 7; break;
      case 1107: atributo = 4; break;
    }

    let mensaje = {
      clase: 'ConfiguracionGeneral',
      id: atributo,
      accion: laAccion,
      atributo: valorE,
      valor: valor,
      prioridad: laPrioridad,
      tipoDato: tipoDato.toUpperCase(),
      permisoGrueso: this.POS ? 'ConfiguracionPos' : 'configuracionGeneral'
    };
    this.enviar(mensaje, false);
  }

  volverValorAnterior(respuesta, id, valorAnterior) {
    if (respuesta === 'editarConfiguracionGeneral') {
      this.contenido[1].configuracionGenerales.forEach(element => {
        if (element.id == id && element.tipo === 'CHECK') {
          element.valor = valorAnterior;
        }
      });
    }
  }

  abrirConfMoneda() {
    this.popup.iniciarPopUp();
  }

  verPopup(codigoError, severidad) {
    this.popUpService.open({ codigoError, severidad });
  }

  actualizarChecks(hijo: any, grupos) {
    if (hijo.codigo > 5201 && hijo.codigo < 5205) {
      grupos.forEach(element => {
        if (element.tipo == 'CHECK' || element.tipo == 'CHECKBUTTON') {
          if (element.codigo != hijo.codigo) {
            if (element.codigo > 5201 && element.codigo < 5205) {
              if (element.valor) {
                element.valor = false;
                this.cambio(element.id, false, 'CHECK');
              }
            }
          }
        }
      });

      if (hijo.codigo == 5202) {
        //DESCUENTO MORA BLOQUEA
        // let nombreColor = <HTMLInputElement>(
        //   document.getElementById('5205Especifico')
        // );
        // if (nombreColor.disabled) {
        //   nombreColor.disabled = false;
        // } else {
        //   nombreColor.disabled = true;
        // }
      }
      let seleccionado = grupos.findIndex(x => x.codigo == 5201); //-1
      if (seleccionado != -1) {
        //Cuando se activa Activar Descuentos Automáticos por cliente y Activar Valor Máximo de Descuento tambien está activo mostrar advertencia de prevalencia
        if (grupos[seleccionado].valor == true && hijo.valor == true) {
          this.popUpService.open({ codigoError: 'prevalenciaDescAuto', severidad: SeveridadEnum.WARNING });
        }
      }
    } else if (hijo.codigo == 5201) {
      let activo = false;
      grupos.forEach(element => {
        if (
          element.valor == true &&
          element.codigo != 5201 &&
          hijo.valor == true
        ) {
          activo = true;
        }
      });
      if (activo) {
        this.popUpService.open({ codigoError: 'prevalenciaDescAuto', severidad: SeveridadEnum.WARNING });
      }
    }

    if (hijo.codigo == 2301) {
      if (hijo.valor == false) {
        this.contenido[0].configuracionGenerales[0].valorCombo = undefined;
      }

    }

  }

  comprobarDisabled(hijo: any) {
    if (hijo.codigo == 5205) {
      let hijoGrande = this.contenido[1].configuracionGenerales.indexOf(
        this.contenido[1].configuracionGenerales.find(function (element) {
          return element.codigo === 5202;
        })
      );
      if (this.contenido[1].configuracionGenerales[hijoGrande].valor) {
        return false;
      } else {
        hijo.valor = false;
        return true;
      }
    } else {
      return false;
    }
  }

  @ViewChildren(SelectPagComponent)
  private selectPagComponent: Array<SelectPagComponent>;
  onScroll(event) {
    this.selectPagComponent.forEach(element => {
      element.abrir = false;
    });
  }

  verificacion(fila) {
    let documentoTipo = this.contenido[fila].documento;
    const MANEJA_FE = documentoTipo == DocumentoTipoEnum.FACTURA_VENTA || documentoTipo == DocumentoTipoEnum.NOTA_CREDITO_VENTA || documentoTipo == DocumentoTipoEnum.NOTA_DEBITO_VENTA;
    if (MANEJA_FE) {
      if ((this.contenido[fila].plantilla == null || this.contenido[fila].plantilla.codigo == -1) || (this.contenido[fila].plantillaFE == null || this.contenido[fila].plantillaFE.codigo == -1)) {
        return true
      }
    } else {
      if (this.contenido[fila].plantilla == null || this.contenido[fila].plantilla.codigo == -1) {
        return true
      }
    }
    return false
  }

  cambioCheckImpresion(id: any, valor: any, tipo: any, fila: any) {
    if (tipo == "plantillaTK") {
      let mensaje = {
        clase: 'ConfiguracionGeneralImpresion',
        id: id,
        accion: 'asociarConfGeneralValor',
        atributo: 'valorComboPlantillaTK',
        valor: valor.codigo,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.STRING],
        permisoGrueso: this.POS ? 'ConfiguracionPos' : 'configuracionGeneral'
      };
      this.enviar(mensaje, false);
    }

    if (tipo === 'senMostrar') {
      sessionStorage.setItem('imprimirDirecto', String(!valor));
      let valido = true;
      if (valor == false) {

        if (this.verificacion(fila)) {
          valido = false;
          let mensaje = 'elegirPlantillaPred';
          const RENGLON = this.contenido[fila];
          //RENGLON.senMostrar = true;
          setTimeout(() => {
            this.contenido[fila].senMostrar = true;
          }, 0);
          //this.contenido = this.contenido.map(x => Object.assign({}, x));
          if (this.validacionMensajePlantilla(RENGLON)) {
            mensaje = 'elegirPlantillaPredFE';
          }
          this.popUpService.open({ codigoError: mensaje, severidad: SeveridadEnum.ERROR });
        }
      }

      if (valido) {
        let mensaje = {
          clase: 'ConfiguracionGeneralImpresion',
          id: id,
          accion: 'editarConfiguracionGeneralImpresion',
          atributo: 'senMostrar',
          valor: valor,
          prioridad: true,
          tipoDato: TipoDato[TipoDato.BOOLEAN],
          permisoGrueso: this.POS ? 'ConfiguracionPos' : 'configuracionGeneral'
        };
        this.enviar(mensaje, false);
      }
    }

    if (tipo === 'imprimirDecimales') {
      let mensaje = {
        clase: 'ConfiguracionGeneralImpresion',
        id: id,
        accion: 'editarConfiguracionGeneralImpresion',
        atributo: 'imprimirDecimales',
        valor: valor,
        prioridad: false,
        tipoDato: TipoDato[TipoDato.BOOLEAN],
        permisoGrueso: this.POS ? 'ConfiguracionPos' : 'configuracionGeneral'
      };
      this.enviar(mensaje, false);
    }

    if (tipo === 'senRevelaciones') {
      let mensaje = {
        clase: 'ConfiguracionGeneralImpresion',
        id: id,
        accion: 'editarConfiguracionGeneralImpresion',
        atributo: 'senRevelaciones',
        valor: valor,
        prioridad: true,
        tipoDato: TipoDato[TipoDato.BOOLEAN],
        permisoGrueso: this.POS ? 'ConfiguracionPos' : 'configuracionGeneral'
      };
      this.enviar(mensaje, false);
    }

    if (tipo === 'plantilla') {
      let valido = true;
      if (valor == null || valor.id == -1) {
        if (!this.contenido[fila].senMostrar) {
          valido = false;
          setTimeout(() => {
            this.contenido[fila].senMostrar = true;
          }, 0);
          this.contenido[fila].plantilla = this.auxPlantillaAnterior;
          this.contenido = this.contenido.map(x => Object.assign({}, x));
          this.popUpService.open({ codigoError: 'elegirPlantillaPredCheck', severidad: SeveridadEnum.ERROR });
        }
      }
      if (valido) {
        let valorE = 'valorCombo';
        let laAccion = 'asociarConfGeneralValor';
        let aux = typeof valor.codigo;
        let tipoDato = TipoDato[TipoDato[aux.toUpperCase()]];
        let mensaje = {
          clase: 'ConfiguracionGeneral',
          id: id,
          accion: laAccion,
          atributo: valorE,
          valor: valor.id,
          prioridad: true,
          tipoDato: tipoDato,
          permisoGrueso: this.POS ? 'ConfiguracionPos' : 'configuracionGeneral'
        };
        if (valor.codigo == -1) {
          this.enviar(mensaje, true, fila, 'plantilla');
        } else {
          this.enviar(mensaje, false);
        }
      }
    }
    if (tipo === 'plantillaFE') {
      let valido = true;
      if (valor == null || valor.id == -1) {
        if (!this.contenido[fila].senMostrar) {
          valido = false;
          this.contenido[fila].plantillaFE = this.auxPlantillaFeAnterior;
          this.contenido = this.contenido.map(x => Object.assign({}, x));
          this.popUpService.open({ codigoError: 'elegirPlantillaPredCheck', severidad: SeveridadEnum.ERROR });
        }
      }
      if (valido) {
        let valorE = 'valorComboPlantillaFE';
        let laAccion = 'asociarConfGeneralValor';
        let tipoDato = TipoDato[TipoDato.STRING];
        let mensaje = {
          clase: 'ConfiguracionGeneral',
          id: id,
          accion: laAccion,
          atributo: valorE,
          valor: valor.codigo,
          prioridad: true,
          tipoDato: tipoDato,
          permisoGrueso: this.POS ? 'ConfiguracionPos' : 'configuracionGeneral'
        };
        if (valor.codigo == -1) {
          this.enviar(mensaje, true, fila, 'plantillaFE');
        } else {
          this.enviar(mensaje, false);
        }
      }
    }
  }

  private validacionMensajePlantilla(RENGLON) {
    return (RENGLON.plantillaFE === null || RENGLON.plantillaFE.id == -1)
      && (RENGLON.documento === DocumentoTipoEnum.FACTURA_VENTA || RENGLON.documento === DocumentoTipoEnum.NOTA_DEBITO_VENTA || RENGLON.documento === DocumentoTipoEnum.NOTA_CREDITO_VENTA);
  }

  enviar(mensaje, ponerNull, fila?, atributo?, dato?) {
    let variable = this.serviceCola.agregarACola(mensaje);

    if (variable) {
      let sub = variable.subscribe(
        response => {
          if (response[0].codigoError) {
            if (dato.cuentaContableEstadoFinanciero.nombre === "Balance General") {
              (this.nivelBalance[dato.nivel - 1] as any).numeroDigitos = response[0].valor;
            }
            if (dato.cuentaContableEstadoFinanciero.nombre === "Ganancias Y Perdidas") {
              (this.nivelPyG[dato.nivel - 1] as any).numeroDigitos = response[0].valor;
            }
            this.verPopup(response[0].codigoError, response[0].severidad)
          }

          if (ponerNull) {
            this.contenido[fila][atributo] = {
              id: -1,
              codigo: -1
            };
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  enviarMensajeAPI(mensaje, recargarTabla) {
    let variable = this.serviceCola.agregarACola(mensaje);

    if (variable) {
      let sub = variable.subscribe(
        response => {
          this.cuentaServicioSelected = null;
          this.activarBtnCrearToken = true;
          if (response[0].codigoError) {
            if (response[0].codigoError == '926') {
              let errorMultiple = response[0].atributo == 'nuevo_token' ? 'generar un token' : response[0].atributo == 'fecha_vencimiento' ? 'modificar la fecha de vencimiento' : '';
              this.popUpService.open({ codigoError: "926", severidad: SeveridadEnum.ERROR, detalles: [errorMultiple] });
            } else {
              this.verPopup(response[0].codigoError, response[0].severidad)
            }
          }
          if (recargarTabla) {

            const { pagina, registrosPorPagina, columna, orden, registroInicial } = this.paginacionListadoToken;
            this.cargarTablaAPI(pagina, registrosPorPagina, columna, orden, registroInicial);
          }
        },
        error => {
          this.errorHandlerComponent.handler(error);
        }
      );
      this.subscriptions.add(sub);
    }
  }

  llenarTabla(registroInicial?) {
    let pestana = this.pestanaImpresiones;
    if (registroInicial !== undefined) {
      this.paginacion.registroInicial = registroInicial;
    }

    this.paginacion.filtros = this.POS
      ? [new FiltroWo('documento', '', null, TipoFiltro.IGUAL, TipoDato.IN, null, ['POS'], 'ConfiguracionGeneral', Operador.AND, 'filtro')]
      : [new FiltroWo('documento', '', null, TipoFiltro.DIFERENTE, TipoDato.NOT_IN, null, ['POS'], 'ConfiguracionGeneral', Operador.AND, 'filtro')];
    let sub = this.configuracionGeneralService.getDatosImpresiones(pestana.codigo, this.paginacion).subscribe(
      response => {
        this.paginacion = {
          totalPaginas: (response as any).totalPages,
          totalElementos: (response as any).totalElements,
          pagina: (response as any).number,
          registrosPorPagina: (response as any).size,
          numeroElementos: (response as any).totalElements,
          registroInicial: registroInicial,
          orden: 'ASC',
          columna: 'descripcion',
          columnaOrdenar: 'descripcion'
        };

        this.contenido = this.scrollInfinitoService.crearArregloScrollInfinito(this.paginacion, this.contenido, (response as any).content);

        this.contenido = (response as any).content;

        this.contenido.forEach((element, index) => {
          if (element.id) {
            let documentoTipo = element.documento;
            const NO_MANEJA_FE = documentoTipo != DocumentoTipoEnum.FACTURA_VENTA && documentoTipo != DocumentoTipoEnum.POS && documentoTipo != DocumentoTipoEnum.NOTA_CREDITO_VENTA && documentoTipo != DocumentoTipoEnum.NOTA_DEBITO_VENTA;
            if (NO_MANEJA_FE) {
              this.contenido[index].mapaBloqueado = new Map<number, Array<number>>();
              this.contenido[index].mapaBloqueado.set(this.contenido[index].id, [2]);
            }
            const NO_MANEJA_TICKET = this.documentosTicket.some(t => t === documentoTipo) ? false : true;
            if (NO_MANEJA_TICKET) {
              this.contenido[index].mapaBloqueado = new Map<number, Array<number>>();
              this.contenido[index].mapaBloqueado.set(this.contenido[index].id, [3]);
            }
            if (element.plantilla != null) {
              let plantilla: Plantilla = {
                id: element.plantilla.id,
                codigo: element.plantilla.codigo,
                valor: element.plantilla.valor,
                nombre: element.plantilla.valor,
              }
              element.plantilla = Object.assign(plantilla);
              this.anteriorPlantilla = Object.assign(plantilla);
            }
            if (element.plantillaFE != null) {
              let plantilla: Plantilla = {
                id: element.plantillaFE.id,
                codigo: element.plantillaFE.codigo,
                valor: element.plantillaFE.valor,
                nombre: element.plantillaFE.valor,
              }
              element.plantillaFE = Object.assign(plantilla);
              this.anteriorPlantillaFE = Object.assign(plantilla);
            }

            if (element.plantillaTK != null) {
              let plantilla: Plantilla = {
                id: element.plantillaTK.id,
                codigo: element.plantillaTK.codigo,
                valor: element.plantillaTK.valor,
                nombre: element.plantillaTK.valor,
              }
              element.plantillaTK = Object.assign(plantilla);
              this.anteriorPlantillaTK = Object.assign(plantilla);
            }
            if (element.tipo == 'valorComboPlantillaFE') {
              if (element.valorCombo != null) {
                let valorInicial = element.valoresDisponibles.indexOf(
                  element.valoresDisponibles.find(function (buscado) {
                    let valorString = '' + buscado.id;
                    return valorString === element.valorCombo;
                  })
                );

                element.valorCombo = element[valorInicial];
              }
              if (element.valorComboPlantillaFE != null) {
                let valorInicial = element.valoresDisponibles.indexOf(
                  element.valoresDisponibles.find(function (buscado) {
                    let valorString = '' + buscado.codigo;
                    return valorString === element.valorComboPlantillaFE;
                  })
                );

                element.valorComboPlantillaFE = element[valorInicial];
              }
            }
          }
        });
        this.contenido = this.contenido.map(x => Object.assign({}, x));
        this.tab = pestana.nombre;
        this.mostrarTabla = true;
      },
      error => {
        this.errorHandlerComponent.handler(error);
      }
    );
    this.subscriptions.add(sub);
  }


  cambioNivel(dato, tipo, valor) {
    let mensaje = {
      clase: 'CuentaContableNivel',
      id: dato.id,
      accion: 'editarCuentaContableNivel',
      atributo: 'numeroDigitos',
      valor: parseInt(valor, 10),
      prioridad: true,
      tipoDato: TipoDato[TipoDato.LONG],
      permisoGrueso: this.POS ? 'ConfiguracionPos' : 'configuracionGeneral'
    };
    this.enviar(mensaje, false, undefined, undefined, dato);
  }

  docAutoCompleto(completo) {
    this.documentosAutoCompleto = completo[0];
    this.mostrarErroresDocAuto = completo[1];
  }

  mostrarAyuda(e) {
    if (e.codigo == 7003) {
      this.popUpService.open({ codigoError: '', severidad: SeveridadEnum.INFO, conBoton: false, messageInHTML: true, detalle: "CONFIGURACION_GENERAL.ayudaAuxilioTransporte", inputs: { maxWidth: '80vw', ayuda: true, titulo: e.descripcion } });
    }
  }

  copiarTexto(configuracion: any) {

    let texto: string = '';

    switch (configuracion.codigo) {
      case 13002:
        texto = '📋 Token copiado con éxito!';
        break
      default:
        texto = 'Texto copiado con éxito'
    }

    const elemento = document.createElement('textarea');
    elemento.value = configuracion.valor;
    document.body.appendChild(elemento);
    elemento.select();
    document.execCommand('copy');
    document.body.removeChild(elemento);

    this.toast.showToast(TOAST_STATE.success, texto)
    setTimeout(() => {
      this.toast.dismissToast()
    }, 2000);
  }

  onClickTable(e) {
    const jwt = this.contentTokens[e.fila].jwt;

    let configuracion = {
      codigo: 13002,
      valor: jwt
    }

    if (e.atributo === 'jwt') {
      this.copiarTexto(configuracion);
    }
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
